<template>
    <b-modal
        id="modalDoctorCreateEdit"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @show="openModalHandler"
        @hidden="$emit('hidden')"
    >
        <template slot="modal-title">
            {{ modalTitle }}
        </template>
        <div class="custom-modal-text text-left">
            <div>
                <div v-if="updatingDoctor" class="form-group">
                    <label for="id">Id</label>
                    <input
                        id="id"
                        :value="data.id"
                        class="form-control"
                        disabled
                        type="text"
                    />
                </div>
                <div class="form-group">
                    <label for="category">Email</label>
                    <input
                        id="category"
                        v-model="data.email"
                        type="text"
                        class="form-control"
                        placeholder="Enter Email"
                    />
                </div>
                <div class="form-group">
                    <label for="first_name">First Name</label>
                    <input
                        id="first_name"
                        v-model="data.first_name"
                        :class="{ 'is-invalid': $v.data.first_name.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Enter first name"
                    />
                </div>
                <div class="form-group">
                    <label for="last_name">Last Name</label>
                    <input
                        id="last_name"
                        v-model="data.last_name"
                        :class="{ 'is-invalid': $v.data.last_name.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Last Name"
                    />
                </div>
                <div class="form-group">
                    <label for="phone">Phone</label>
                    <input
                        id="phone"
                        v-model="data.phone"
                        :class="{ 'is-invalid': $v.data.phone.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Enter phone number"
                    />
                </div>
                <div class="form-group">
                    <label for="job_type">Job type</label>
                    <b-form-select
                        :class="{ 'is-invalid': $v.data.job_type.$error }"
                        v-model="data.job_type"
                        :options="JOB_TYPE"
                        text-field="text"
                    />
                </div>
                <div class="form-group">
                    <label for="speciality">Speciality</label>
                    <b-form-select
                        v-model="data.speciality"
                        :options="SPECIALITY"
                        text-field="value"
                    />
                </div>
                <div class="form-group">
                    <label for="medicalId">Medical ID</label>
                    <input
                        id="medicalId"
                        v-model="data.medical_id"
                        type="text"
                        class="form-control"
                        placeholder="Enter medical ID"
                    />
                </div>
                <div class="form-group">
                    <label for="medical_institute">Medical Institute</label>
                    <b-form-input
                        v-model="data.medical_institute"
                        list="institutes_list"
                    />
                    <datalist id="institutes_list">
                        <option
                            v-for="{ value } in MEDICAL_INSTITUTE"
                            :key="value"
                        >
                            {{ value }}
                        </option>
                    </datalist>
                </div>
                <div class="form-group">
                    <label for="city">City</label>
                    <b-form-input v-model="data.city" list="cities_list" />
                    <datalist id="cities_list">
                        <option v-for="{ value } in ISRAEL_CITIES" :key="value">
                            {{ value }}
                        </option>
                    </datalist>
                </div>
            </div>
        </div>

        <div v-if="updatingDoctor">
            <div class="table-responsive">
                <table
                    id="events-datatable"
                    class="table table-centered table-striped text-center"
                >
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="event in data.events" :key="event.id">
                            <td>{{ event.title }}</td>
                            <td>{{ event.date | formatDate }}</td>
                            <td v-if="event.UserEvent.status === 'approved'">
                                <span
                                    class="badge bg-soft-success text-success"
                                >
                                    Approved
                                </span>
                            </td>
                            <td v-else>
                                <span class="badge bg-soft-danger text-danger">
                                    Pending
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="submitDoctorDataHandler"
                >
                    {{ buttonText }}
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {
    SPECIALITY,
    MEDICAL_INSTITUTE,
    ISRAEL_CITIES,
    JOB_TYPE
} from '../../common/constants';

export default {
    props: {
        updatingDoctor: {
            type: Boolean,
            required: false,
            default: true
        },
        doctor: {
            type: Object,
            required: false,
            default: null
        },
        modalTitle: {
            type: String,
            required: false,
            default: 'Add New Doctor'
        },
        buttonText: {
            type: String,
            required: false,
            default: 'Save'
        }
    },

    data() {
        return {
            data: {},
            events: null,
            SPECIALITY,
            MEDICAL_INSTITUTE,
            ISRAEL_CITIES,
            JOB_TYPE
        };
    },

    validations: {
        data: {
            first_name: {
                required
            },
            last_name: {
                required
            },
            phone: {
                required
            },
            job_type: {
                required
            }
        }
    },

    methods: {
        hideModal() {
            this.$bvModal.hide('modalDoctorCreateEdit');
        },

        async openModalHandler() {
            await this.$nextTick();

            if (this.doctor && this.updatingDoctor) {
                this.data = { ...this.doctor };

                return;
            }

            this.data = {};
        },

        submitDoctorDataHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.$emit(
                this.updatingDoctor ? 'update-doctor' : 'create-doctor',
                this.data
            );

            this.$v.$reset();
            this.hideModal();
        }
    }
};
</script>
