<script>
import { parse } from 'json2csv';
import { mapActions } from 'vuex';
import moment from 'moment';
import TableView from '@components/elements/TableView';
import ConfirmModal from '@components/elements/ConfirmModal';
import ModalDoctorCreateEdit from '@components/doctors/ModalDoctorCreateEdit';

export default {
    page: {
        title: 'Doctors Management'
    },

    components: {
        ModalDoctorCreateEdit,
        ConfirmModal
    },

    extends: TableView,

    data() {
        return {
            pageTitle: 'Doctors Management',
            activeTab: { value: 'existing' },
            doctorToUpdate: null,
            doctorToDelete: null,
            modalTitle: ''
        };
    },

    computed: {
        additionalComponents() {
            return [
                {
                    name: 'modal-doctor-create-edit',
                    props: {
                        modalTitle: this.doctorToUpdate
                            ? 'Edit Doctor'
                            : 'Add New Doctor',
                        buttonText: this.doctorToUpdate ? 'Update' : 'Save',
                        updatingDoctor: !!this.doctorToUpdate,
                        doctor: this.doctorToUpdate
                    },
                    events: {
                        hidden: this.hideModal,
                        'create-doctor': this.createDoctorHandler,
                        'update-doctor': this.updateDoctorHandler
                    }
                },
                {
                    name: 'confirm-modal',
                    props: {
                        title: this.modalTitle
                    },
                    events: {
                        agree: this.doctorToDelete
                            ? this.deleteDoctorHandler
                            : this.approveDoctorHandler,
                        disagree: this.hideModal
                    }
                }
            ];
        },

        labels() {
            if (this.activeTab.value === 'csvs') {
                return [
                    { value: 'name', width: '200px' },
                    {
                        value: 'created_at',
                        filter: 'formatDate',
                        width: '200px',
                        type: 'date'
                    }
                ];
            }

            return [
                { value: 'id', width: '200px' },
                { value: 'email', width: '200px' },
                { value: 'first_name', width: '180px' },
                {
                    value: 'last_name',
                    width: '180px'
                },
                {
                    value: 'phone',
                    width: '200px'
                },
                {
                    value: 'speciality',
                    width: '200px'
                },
                {
                    value: 'job_type',
                    width: '200px'
                },
                {
                    value: 'medical_id',
                    width: '200px'
                },
                {
                    value: 'medical_institute',
                    width: '200px'
                },
                {
                    value: 'city',
                    width: '200px'
                },
                {
                    text: 'Novo employee',
                    value: 'is_novo_employee',
                    filter: 'formatBoolean',
                    width: '200px',
                    type: 'boolean'
                },
                {
                    value: 'status',
                    width: '200px',
                    sortable: false
                },
                {
                    text: 'Marketing accepted',
                    value: 'marketing_accepted',
                    filter: 'formatBoolean',
                    width: '100px',
                    type: 'boolean'
                },
                {
                    text: 'Notifications accepted',
                    value: 'device_token',
                    filter: 'formatBoolean',
                    width: '100px',
                    type: 'boolean'
                },
                {
                    text: 'First login at',
                    value: 'first_login_at',
                    filter: 'formatFullDate',
                    width: '200px',
                    type: 'date'
                },
                {
                    text: 'Inactive',
                    value: 'is_inactive',
                    width: '100px',
                    type: 'boolean',
                    filter: 'formatBoolean'
                },
                {
                    text: 'Last active at',
                    value: 'last_active_at',
                    filter: 'formatFullDate',
                    width: '200px',
                    type: 'date'
                },
                {
                    text:
                        this.activeTab.value === 'existing'
                            ? 'Created At'
                            : 'Request Date',
                    value: 'created_at',
                    filter: 'formatDate',
                    width: '200px',
                    type: 'date'
                },
                {
                    value: 'updated_at',
                    filter: 'formatDate',
                    width: '200px',
                    type: 'date'
                }
            ];
        },

        actions() {
            if (this.activeTab.value === 'existing') {
                return [
                    {
                        icon: 'fas fa-pen mr-1',
                        method: 'showEditModal'
                    }
                ];
            }

            if (this.activeTab.value === 'pending') {
                return [
                    {
                        icon: 'fas fa-check-circle',
                        method: 'showApproveModal'
                    }
                ];
            }

            return [
                {
                    icon: 'fas fa-download',
                    method: 'downloadCsvFile'
                },
                {
                    icon: 'far fa-trash-alt',
                    method: 'deleteCsvFile'
                }
            ];
        },

        globalActions() {
            return this.activeTab.value === 'existing'
                ? [
                      {
                          icon: 'fas fa-download',
                          name: 'Export login logs',
                          method: 'generateLoginLogs'
                      },
                      {
                          icon: 'fas fa-download',
                          name: 'Export all doctors',
                          method: 'exportDoctors',
                          params: { onlyActive: false }
                      }
                  ]
                : [];
        },

        tabsElements() {
            return [
                { value: 'existing', text: 'Existing doctors' },
                { value: 'pending', text: 'Pending approval doctors' },
                { value: 'csvs', text: 'Doctors CSV files' }
            ];
        },

        additionalFetchData() {
            return { isApproved: this.activeTab.value === 'existing' };
        },

        options() {
            return { addButton: this.activeTab.value === 'existing' };
        },

        importPath() {
            return this.activeTab.value === 'existing';
        },

        includeDeleteAction() {
            return this.activeTab.value !== 'csvs';
        }
    },

    methods: {
        ...mapActions({
            getDoctors: 'doctors/index',
            updateDoctor: 'doctors/update',
            createDoctor: 'doctors/store',
            deleteDoctor: 'doctors/destroy',
            approveDoctor: 'doctors/approve',
            addDoctors: 'doctors/addDoctors',
            csvExport: 'doctors/csvExport',
            getCsvs: 'csvs/index',
            downloadCsv: 'csvs/download',
            deleteCsv: 'csvs/delete'
        }),

        getItems(data) {
            if (this.activeTab.value === 'csvs') {
                return this.getCsvs(data);
            }

            return this.getDoctors(data);
        },

        onAddButtonClick() {
            this.$bvModal.show('modalDoctorCreateEdit');
        },

        showEditModal(doctor) {
            this.doctorToUpdate = doctor;

            this.$bvModal.show('modalDoctorCreateEdit');
        },

        showDeleteModal(doctor) {
            this.doctorToDelete = doctor;
            this.modalTitle =
                'Please confirm that you want to delete this doctor';

            this.$bvModal.show('confirmModal');
        },

        showApproveModal(doctor) {
            this.doctorToUpdate = doctor;
            this.modalTitle =
                'Please confirm that you want to approve this user';

            this.$bvModal.show('confirmModal');
        },

        onRowClick(doctor) {
            this.$router.push(`/doctors-management/${doctor.id}`);
        },

        hideModal() {
            this.doctorToUpdate = null;
            this.doctorToDelete = null;
        },

        async createDoctorHandler(data) {
            data.is_approved = true;

            try {
                const doctor = await this.createDoctor(data);

                this.items = [doctor, ...this.items];

                this.$bvToast.toast('Doctor has been added.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                const message = err.response.data || 'Something went wrong.';

                this.$bvToast.toast(message, {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async updateDoctorHandler(data) {
            try {
                const response = await this.updateDoctor(data);

                const index = this.items.findIndex(i => data.id === i.id);
                this.items[index] = { ...response };
                this.items = this.items.map(i => ({ ...i }));

                this.$bvToast.toast('Doctor has been updated.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                const message = err.response.data || 'Something went wrong.';

                this.$bvToast.toast(message, {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async deleteDoctorHandler() {
            try {
                await this.deleteDoctor(this.doctorToDelete);

                this.items = this.items.filter(
                    item => item.id !== this.doctorToDelete.id
                );
                this.doctorToDelete = null;
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async approveDoctorHandler() {
            const data = { ...this.doctorToUpdate };
            data.is_approved = true;

            try {
                await this.approveDoctor(data);

                this.items = this.items.filter(item => item.id !== data.id);
                this.doctorToUpdate = null;
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async onImport(data) {
            try {
                await this.addDoctors(data);

                await this.fetchItems();

                this.$bvToast.toast('Doctors have been added.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async generateLoginLogs() {
            try {
                const content = await this.csvExport();
                const fileURL = window.URL.createObjectURL(
                    new Blob([`\ufeff${content}`])
                );

                this.downloadFile(fileURL, 'login_logs');
            } catch (error) {
                console.error(error);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        downloadFile(url, name) {
            const fileName = `${name}_${moment().format(
                'YYYY-MM-DD_HH-mm-ss'
            )}.csv`;

            this.$downloadFile(url, fileName);
        },

        async downloadCsvFile(item) {
            const csv = await this.downloadCsv(item.id);

            const csvBlob = new Blob([csv], {
                type: 'text/csv;charset=utf-8;'
            });
            const csvURL = window.URL.createObjectURL(csvBlob);

            this.$downloadFile(csvURL, item.name);
        },

        async deleteCsvFile(csv) {
            try {
                await this.deleteCsv(csv.id);

                this.items = this.items.filter(item => item.id !== csv.id);

                this.$bvToast.toast('File has been deleted.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (error) {
                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        getRowClass(item) {
            if (item.is_novo_employee) {
                return 'row-green';
            }

            return '';
        },

        async exportDoctors(_, __, { onlyActive }) {
            try {
                const options = {
                    currentPage: 1,
                    perPage: this.pagination.total,
                    attributes: [
                        'email',
                        'phone',
                        'first_name',
                        'last_name',
                        'speciality',
                        'medical_id',
                        'job_type',
                        'medical_institute',
                        'city',
                        'marketing_accepted',
                        'first_login_at',
                        'last_active_at',
                        'created_at'
                    ],
                    filters: {
                        email: {
                            type: 'notRegexp',
                            value: '.*novonordisk.[^\\s]+|.*prpl.[^\\s]+'
                        }
                    }
                };

                if (onlyActive) {
                    options.filters.device_id = {
                        type: 'not',
                        value: '0'
                    };
                }

                const { rows: doctors } = await this.getDoctors(options);

                const formatDate = date =>
                    date ? moment(date).format('DD/MM/YYYY HH:kk') : '';

                const formatBoolean = bool => (bool ? 'Yes' : 'No');

                const fields = [
                    { value: 'email', label: 'Email' },
                    { value: 'phone', label: 'Phone' },
                    { value: 'first_name', label: 'First name' },
                    { value: 'last_name', label: 'Last name' },
                    { value: 'medical_id', label: 'Medical ID' },
                    { value: 'speciality', label: 'Speciality' },
                    { value: 'job_type', label: 'Job type' },
                    { value: 'medical_institute', label: 'Medical institute' },
                    { value: 'city', label: 'City' },
                    {
                        value: ({ marketing_accepted }) =>
                            formatBoolean(marketing_accepted),
                        label: 'Marketing accepted'
                    },
                    {
                        value: ({ device_token }) =>
                            formatBoolean(device_token),
                        label: 'Notifications accepted'
                    },
                    {
                        value: ({ first_login_at }) =>
                            formatDate(first_login_at),
                        label: 'First login at'
                    },
                    {
                        value: ({ last_active_at }) =>
                            formatDate(last_active_at),
                        label: 'Last active at'
                    },
                    {
                        value: ({ created_at }) => formatDate(created_at),
                        label: 'Registered at'
                    }
                ];

                const content = parse(doctors, { fields });

                const fileURL = window.URL.createObjectURL(
                    new Blob([`\ufeff${content}`])
                );

                this.downloadFile(fileURL, 'doctors');
            } catch (error) {
                console.error(error);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        }
    }
};
</script>
