export const JOB_TYPE = [
    { value: 'ד"ר', text: 'ד"ר' },
    { value: "פרופ'", text: "פרופ'" },
    { value: "גב'", text: "'גב" },
    { value: 'מר', text: 'מר' },
    { value: "מגר'", text: "'מגר" }
];

export const SPECIALITY = [
    { value: 'משפחה' },
    { value: 'פנימית' },
    { value: 'קרדיולוגיה' },
    { value: 'אנדוקרינולוגיה' },
    { value: 'סכרת' },
    { value: 'גסטרואנטרולוגיה' },
    { value: 'גינקולוגיה' },
    { value: 'כירורגיה' },
    { value: 'אנדוקרינולוגיית ילדים' },
    { value: 'רפואת ילדים' },
    { value: 'רוקח/רוקחת' },
    { value: 'אח/אחות' },
    { value: 'דיאטה' },
    { value: 'אדמניסטרציה' },
    { value: 'אונקולוגיה' },
    { value: 'אורולוגיה' },
    { value: 'אח/ות הדרכה קלינית' },
    { value: 'אח/ות טיפול נמרץ' },
    { value: 'אח/ות מוסמכת' },
    { value: 'אח/ות סוכרת' },
    { value: 'אח/ות רפואה דחופה' },
    { value: 'אחות אנדו פדיאטריה' },
    { value: 'אחות ברהצ - טיפת חלב' },
    { value: 'אחר' },
    { value: 'אף_ אוזן_ גרון' },
    { value: 'אח/אחות אונקולוגיה' },
    { value: 'אח/אחות סוכרת' },
    { value: 'אח/אחות סטומה' },
    { value: 'אח/ות טיפול נמרץ ילדים ופגים' },
    { value: 'גסטרואנטרולוגיית ילדים ומתבגרים' },
    { value: 'גריאטריה' },
    { value: 'דרמטולוגיה' },
    { value: 'המטולוגיה' },
    { value: 'הרדמה' },
    { value: 'כאב' },
    { value: 'כירורגיה כללית' },
    { value: 'גנטיקה' },
    { value: 'כללית' },
    { value: 'מומחה סוכרת' },
    { value: 'מוסד רפואי' },
    { value: 'מחלות זיהומיות' },
    { value: 'מנהל אדמיניסטרטיבי' },
    { value: 'מנהל רפואי' },
    { value: 'פנימאי/ת בית חולים' },
    { value: 'נוירולוגיה' },
    { value: 'נפרולוגיה' },
    { value: 'עבודה סוציאלית' },
    { value: 'עיניים' },
    { value: 'פולמונולוגיה' },
    { value: 'פיזיותרפיה' },
    { value: 'פנימאי בקהילה' },
    { value: 'פסיכיאטריה' },
    { value: 'פסיכיאטריית ילדים' },
    { value: 'ראומטולוגיה' },
    { value: 'רופא משפחה' },
    { value: 'רפואה אלטרנטיבית' },
    { value: 'רפואה דחופה' },
    { value: 'רפואה פליאטיבית' },
    { value: 'רפואה תעסוקתית' },
    { value: 'שיניים' },
    { value: 'שיקום' }
];

export const MEDICAL_INSTITUTE = [
    { value: 'כללית' },
    { value: 'מכבי' },
    { value: 'לאומית' },
    { value: 'מאוחדת' },
    { value: 'מעייני הישועה' },
    { value: 'איטלקי - המשפחה הקדושה נצרת' },
    { value: 'איכילוב / המרכז הרפואי על שם סוראסקי' },
    { value: 'אנגלי נצרת' },
    { value: 'בילינסון' },
    { value: 'ביקור חולים - שלוחה של שערי צדק' },
    { value: 'בית דנה' },
    { value: 'אסותא רמת החייל' },
    { value: 'בני ציון (רוטשילד)' },
    { value: 'ברזילי' },
    { value: 'הדסה הר צופים' },
    { value: 'הדסה עין כרם' },
    { value: 'הלל יפה' },
    { value: 'העמק' },
    { value: 'השרון' },
    { value: 'וולפסון' },
    { value: 'כרמל' },
    { value: 'לניאדו' },
    { value: 'מאיר' },
    { value: 'מרכז רפואי אסף הרופא' },
    { value: 'נהריה - גליל מערבי' },
    { value: 'סורוקה' },
    { value: 'פוריה' },
    { value: 'צרפתי' },
    { value: 'קפלן' },
    { value: 'רבקה זיו' },
    { value: 'רמב״ם' },
    { value: 'שיבא - תל השומר' },
    { value: 'שניידר' },
    { value: 'שערי צדק' },
    { value: 'אסותא אשדוד' },
    { value: 'אחר' }
];

export const ISRAEL_CITIES = [
    {
        id: 967,
        value: "אבו ג'ווייעד (שבט)",
        engName: "ABU JUWEI'ID"
    },
    {
        id: 472,
        value: 'אבו גוש',
        engName: 'ABU GHOSH'
    },
    {
        id: 473,
        value: 'אבו סנאן',
        engName: 'ABU SINAN'
    },
    {
        id: 935,
        value: 'אבו סריחאן (שבט)',
        engName: 'ABU SUREIHAN'
    },
    {
        id: 958,
        value: 'אבו עבדון (שבט)',
        engName: 'ABU  ABDUN'
    },
    {
        id: 1042,
        value: 'אבו עמאר (שבט)',
        engName: 'ABU  AMMAR'
    },
    {
        id: 932,
        value: 'אבו עמרה (שבט)',
        engName: 'ABU  AMRE'
    },
    {
        id: 1342,
        value: 'אבו קורינאת (יישוב)',
        engName: 'ABU QUREINAT'
    },
    {
        id: 968,
        value: 'אבו קורינאת (שבט)',
        engName: 'ABU QUREINAT'
    },
    {
        id: 966,
        value: 'אבו רובייעה (שבט)',
        engName: "ABU RUBEI'A"
    },
    {
        id: 961,
        value: 'אבו רוקייק (שבט)',
        engName: 'ABU RUQAYYEQ'
    },
    {
        id: 1375,
        value: 'אבו תלול',
        engName: 'ABU TULUL'
    },
    {
        id: 652,
        value: 'אבטין',
        engName: 'IBTIN'
    },
    {
        id: 1275,
        value: 'אבטליון',
        engName: 'AVTALYON'
    },
    {
        id: 679,
        value: 'אביאל',
        engName: "AVI'EL"
    },
    {
        id: 1115,
        value: 'אביבים',
        engName: 'AVIVIM'
    },
    {
        id: 819,
        value: 'אביגדור',
        engName: 'AVIGEDOR'
    },
    {
        id: 175,
        value: 'אביחיל',
        engName: 'AVIHAYIL'
    },
    {
        id: 2052,
        value: 'אביטל',
        engName: 'AVITAL'
    },
    {
        id: 1070,
        value: 'אביעזר',
        engName: "AVI'EZER"
    },
    {
        id: 1220,
        value: 'אבירים',
        engName: 'ABBIRIM'
    },
    {
        id: 182,
        value: 'אבן יהודה',
        engName: 'EVEN YEHUDA'
    },
    {
        id: 1081,
        value: 'אבן מנחם',
        engName: 'EVEN MENAHEM'
    },
    {
        id: 783,
        value: 'אבן ספיר',
        engName: 'EVEN SAPPIR'
    },
    {
        id: 400,
        value: 'אבן שמואל',
        engName: "EVEN SHEMU'EL"
    },
    {
        id: 4011,
        value: 'אבני איתן',
        engName: 'AVNE ETAN'
    },
    {
        id: 3793,
        value: 'אבני חפץ',
        engName: 'AVNE HEFEZ'
    },
    {
        id: 3786,
        value: 'אבנת',
        engName: 'AVENAT'
    },
    {
        id: 1311,
        value: 'אבשלום',
        engName: 'AVSHALOM'
    },
    {
        id: 3759,
        value: 'אדורה',
        engName: 'ADORA'
    },
    {
        id: 113,
        value: 'אדירים',
        engName: 'ADDIRIM'
    },
    {
        id: 1068,
        value: 'אדמית',
        engName: 'ADAMIT'
    },
    {
        id: 1123,
        value: 'אדרת',
        engName: 'ADDERET'
    },
    {
        id: 446,
        value: 'אודים',
        engName: 'UDIM'
    },
    {
        id: 4010,
        value: 'אודם',
        engName: 'ODEM'
    },
    {
        id: 1046,
        value: 'אוהד',
        engName: 'OHAD'
    },
    {
        id: 2710,
        value: 'אום אל-פחם',
        engName: 'UMM AL-FAHM'
    },
    {
        id: 2024,
        value: 'אום אל-קוטוף',
        engName: 'UMM AL-QUTUF'
    },
    {
        id: 1358,
        value: 'אום בטין',
        engName: 'UMM BATIN'
    },
    {
        id: 1108,
        value: 'אומן',
        engName: 'OMEN'
    },
    {
        id: 680,
        value: 'אומץ',
        engName: 'OMEZ'
    },
    {
        id: 31,
        value: 'אופקים',
        engName: 'OFAQIM'
    },
    {
        id: 1294,
        value: 'אור הגנוז',
        engName: 'OR HAGANUZ'
    },
    {
        id: 67,
        value: 'אור הנר',
        engName: 'OR HANER'
    },
    {
        id: 2400,
        value: 'אור יהודה',
        engName: 'OR YEHUDA'
    },
    {
        id: 1020,
        value: 'אור עקיבא',
        engName: 'OR AQIVA'
    },
    {
        id: 780,
        value: 'אורה',
        engName: 'ORA'
    },
    {
        id: 1771,
        value: 'אורון',
        engName: 'ORON'
    },
    {
        id: 2012,
        value: 'אורות',
        engName: 'OROT'
    },
    {
        id: 4013,
        value: 'אורטל',
        engName: 'ORTAL'
    },
    {
        id: 403,
        value: 'אורים',
        engName: 'URIM'
    },
    {
        id: 882,
        value: 'אורנים',
        engName: 'ORANIM'
    },
    {
        id: 3760,
        value: 'אורנית',
        engName: 'ORANIT'
    },
    {
        id: 278,
        value: 'אושה',
        engName: 'USHA'
    },
    {
        id: 565,
        value: 'אזור',
        engName: 'AZOR'
    },
    {
        id: 1157,
        value: 'אחווה',
        engName: 'AHAWA'
    },
    {
        id: 821,
        value: 'אחוזם',
        engName: 'AHUZZAM'
    },
    {
        id: 1330,
        value: 'אחוזת ברק',
        engName: 'AHUZZAT BARAQ'
    },
    {
        id: 785,
        value: 'אחיהוד',
        engName: 'AHIHUD'
    },
    {
        id: 850,
        value: 'אחיטוב',
        engName: 'AHITUV'
    },
    {
        id: 804,
        value: 'אחיסמך',
        engName: 'AHISAMAKH'
    },
    {
        id: 797,
        value: 'אחיעזר',
        engName: "AHI'EZER"
    },
    {
        id: 965,
        value: 'אטרש (שבט)',
        engName: 'ATRASH'
    },
    {
        id: 338,
        value: 'איבים',
        engName: 'IBBIM'
    },
    {
        id: 716,
        value: 'אייל',
        engName: 'EYAL'
    },
    {
        id: 77,
        value: 'איילת השחר',
        engName: 'AYYELET HASHAHAR'
    },
    {
        id: 294,
        value: 'אילון',
        engName: 'ELON'
    },
    {
        id: 1126,
        value: 'אילות',
        engName: 'ELOT'
    },
    {
        id: 49,
        value: 'אילנייה',
        engName: 'ILANIYYA'
    },
    {
        id: 2600,
        value: 'אילת',
        engName: 'EILAT'
    },
    {
        id: 1336,
        value: 'אירוס',
        engName: 'IRUS'
    },
    {
        id: 3762,
        value: 'איתמר',
        engName: 'ITAMAR'
    },
    {
        id: 37,
        value: 'איתן',
        engName: 'ETAN'
    },
    {
        id: 886,
        value: 'איתנים',
        engName: 'ETANIM'
    },
    {
        id: 478,
        value: 'אכסאל',
        engName: 'IKSAL'
    },
    {
        id: 1339,
        value: 'אל -עזי',
        engName: 'AL-AZY'
    },
    {
        id: 1316,
        value: 'אל -עריאן',
        engName: 'AL-ARYAN'
    },
    {
        id: 4003,
        value: 'אל -רום',
        engName: 'EL-ROM'
    },
    {
        id: 1359,
        value: 'אל סייד',
        engName: 'AL SAYYID'
    },
    {
        id: 1145,
        value: 'אלומה',
        engName: 'ALUMMA'
    },
    {
        id: 330,
        value: 'אלומות',
        engName: 'ALUMMOT'
    },
    {
        id: 1182,
        value: 'אלון הגליל',
        engName: 'ALLON HAGALIL'
    },
    {
        id: 3579,
        value: 'אלון מורה',
        engName: 'ELON MORE'
    },
    {
        id: 3604,
        value: 'אלון שבות',
        engName: 'ALLON SHEVUT'
    },
    {
        id: 429,
        value: 'אלוני אבא',
        engName: 'ALLONE ABBA'
    },
    {
        id: 4017,
        value: 'אלוני הבשן',
        engName: 'ALLONE HABASHAN'
    },
    {
        id: 868,
        value: 'אלוני יצחק',
        engName: 'ALLONE YIZHAQ'
    },
    {
        id: 285,
        value: 'אלונים',
        engName: 'ALLONIM'
    },
    {
        id: 4002,
        value: 'אלי-עד',
        engName: 'ELI-AD'
    },
    {
        id: 1365,
        value: 'אליאב',
        engName: 'ELIAV'
    },
    {
        id: 41,
        value: 'אליכין',
        engName: 'ELYAKHIN'
    },
    {
        id: 1248,
        value: 'אליפז',
        engName: 'ELIFAZ'
    },
    {
        id: 730,
        value: 'אליפלט',
        engName: 'ELIFELET'
    },
    {
        id: 682,
        value: 'אליקים',
        engName: 'ELYAQIM'
    },
    {
        id: 204,
        value: 'אלישיב',
        engName: 'ELYASHIV'
    },
    {
        id: 841,
        value: 'אלישמע',
        engName: 'ELISHAMA'
    },
    {
        id: 1125,
        value: 'אלמגור',
        engName: 'ALMAGOR'
    },
    {
        id: 3556,
        value: 'אלמוג',
        engName: 'ALMOG'
    },
    {
        id: 1309,
        value: 'אלעד',
        engName: "EL'AD"
    },
    {
        id: 3618,
        value: 'אלעזר',
        engName: "EL'AZAR"
    },
    {
        id: 3750,
        value: 'אלפי מנשה',
        engName: 'ALFE MENASHE'
    },
    {
        id: 603,
        value: 'אלקוש',
        engName: 'ELQOSH'
    },
    {
        id: 3560,
        value: 'אלקנה',
        engName: 'ELQANA'
    },
    {
        id: 772,
        value: 'אמונים',
        engName: 'EMUNIM'
    },
    {
        id: 1064,
        value: 'אמירים',
        engName: 'AMIRIM'
    },
    {
        id: 1253,
        value: 'אמנון',
        engName: 'AMNUN'
    },
    {
        id: 23,
        value: 'אמציה',
        engName: 'AMAZYA'
    },
    {
        id: 4012,
        value: 'אניעם',
        engName: "ANI'AM"
    },
    {
        id: 960,
        value: 'אסד (שבט)',
        engName: 'ASAD'
    },
    {
        id: 3754,
        value: 'אספר',
        engName: 'ASFAR'
    },
    {
        id: 529,
        value: 'אעבלין',
        engName: "I'BILLIN"
    },
    {
        id: 963,
        value: 'אעצם (שבט)',
        engName: "A'SAM"
    },
    {
        id: 959,
        value: 'אפיניש (שבט)',
        engName: 'AFEINISH'
    },
    {
        id: 4301,
        value: 'אפיק',
        engName: 'AFIQ'
    },
    {
        id: 176,
        value: 'אפיקים',
        engName: 'AFIQIM'
    },
    {
        id: 313,
        value: 'אפק',
        engName: 'AFEQ'
    },
    {
        id: 3650,
        value: 'אפרת',
        engName: 'EFRAT'
    },
    {
        id: 701,
        value: 'ארבל',
        engName: 'ARBEL'
    },
    {
        id: 3598,
        value: 'ארגמן',
        engName: 'ARGAMAN'
    },
    {
        id: 714,
        value: 'ארז',
        engName: 'EREZ'
    },
    {
        id: 3570,
        value: 'אריאל',
        engName: "ARI'EL"
    },
    {
        id: 1324,
        value: 'ארסוף',
        engName: 'ARSUF'
    },
    {
        id: 71,
        value: 'אשבול',
        engName: 'ESHBOL'
    },
    {
        id: 1276,
        value: 'אשבל',
        engName: 'ESHBAL'
    },
    {
        id: 70,
        value: 'אשדוד',
        engName: 'ASHDOD'
    },
    {
        id: 199,
        value: 'אשדות יעקב (איחוד)',
        engName: "ASHDOT YA'AQOV(IHUD)"
    },
    {
        id: 188,
        value: 'אשדות יעקב (מאוחד)',
        engName: "ASHDOT YA'AQOV(ME'UHAD)"
    },
    {
        id: 1188,
        value: 'אשחר',
        engName: 'ESHHAR'
    },
    {
        id: 3722,
        value: 'אשכולות',
        engName: 'ESHKOLOT'
    },
    {
        id: 2021,
        value: 'אשל הנשיא',
        engName: 'ESHEL HANASI'
    },
    {
        id: 1152,
        value: 'אשלים',
        engName: 'ASHALIM'
    },
    {
        id: 7100,
        value: 'אשקלון',
        engName: 'ASHQELON'
    },
    {
        id: 1256,
        value: 'אשרת',
        engName: 'ASHERAT'
    },
    {
        id: 740,
        value: 'אשתאול',
        engName: "ESHTA'OL"
    },
    {
        id: 6000,
        value: 'באקה אל-גרביה',
        engName: 'BAQA AL-GHARBIYYE'
    },
    {
        id: 21,
        value: 'באר אורה',
        engName: "BE'ER ORA"
    },
    {
        id: 1376,
        value: 'באר גנים',
        engName: "BE'ER GANNIM"
    },
    {
        id: 155,
        value: 'באר טוביה',
        engName: 'BEER TOVIYYA'
    },
    {
        id: 2530,
        value: 'באר יעקב',
        engName: 'BEER YAAKOV'
    },
    {
        id: 1278,
        value: 'באר מילכה',
        engName: "BE'ER MILKA"
    },
    {
        id: 9000,
        value: 'באר שבע',
        engName: 'BEER SHEVA'
    },
    {
        id: 450,
        value: 'בארות יצחק',
        engName: 'BEEROT YIZHAQ'
    },
    {
        id: 697,
        value: 'בארותיים',
        engName: "B'EROTAYIM"
    },
    {
        id: 399,
        value: 'בארי',
        engName: "BE'ERI"
    },
    {
        id: 559,
        value: 'בוסתן הגליל',
        engName: 'BUSTAN HAGALIL'
    },
    {
        id: 482,
        value: "בועיינה-נוג'ידאת",
        engName: "BU'EINE-NUJEIDAT"
    },
    {
        id: 4001,
        value: 'בוקעאתא',
        engName: "BUQ'ATA"
    },
    {
        id: 698,
        value: 'בורגתה',
        engName: 'BURGETA'
    },
    {
        id: 2043,
        value: 'בחן',
        engName: 'BAHAN'
    },
    {
        id: 762,
        value: 'בטחה',
        engName: 'BITHA'
    },
    {
        id: 234,
        value: 'ביצרון',
        engName: 'BIZZARON'
    },
    {
        id: 998,
        value: 'ביר אל-מכסור',
        engName: 'BIR EL-MAKSUR'
    },
    {
        id: 1348,
        value: "ביר הדאג'",
        engName: 'BIR HADAGE'
    },
    {
        id: 368,
        value: 'בירייה',
        engName: 'BIRIYYA'
    },
    {
        id: 317,
        value: 'בית אורן',
        engName: 'BET OREN'
    },
    {
        id: 3574,
        value: 'בית אל',
        engName: 'BET EL'
    },
    {
        id: 562,
        value: 'בית אלעזרי',
        engName: "BET EL'AZARI"
    },
    {
        id: 95,
        value: 'בית אלפא',
        engName: 'BET ALFA'
    },
    {
        id: 3652,
        value: 'בית אריה',
        engName: 'BET ARYE'
    },
    {
        id: 1076,
        value: 'בית ברל',
        engName: 'BET BERL'
    },
    {
        id: 480,
        value: "בית ג'ן",
        engName: 'BEIT JANN'
    },
    {
        id: 619,
        value: 'בית גוברין',
        engName: 'BET GUVRIN'
    },
    {
        id: 571,
        value: 'בית גמליאל',
        engName: "BET GAMLI'EL"
    },
    {
        id: 466,
        value: 'בית דגן',
        engName: 'BET DAGAN'
    },
    {
        id: 723,
        value: 'בית הגדי',
        engName: 'BET HAGADDI'
    },
    {
        id: 373,
        value: 'בית הלוי',
        engName: 'BET HALEWI'
    },
    {
        id: 322,
        value: 'בית הלל',
        engName: 'BET HILLEL'
    },
    {
        id: 572,
        value: 'בית העמק',
        engName: 'BET HAEMEQ'
    },
    {
        id: 3645,
        value: 'בית הערבה',
        engName: 'BET HAARAVA'
    },
    {
        id: 242,
        value: 'בית השיטה',
        engName: 'BET HASHITTA'
    },
    {
        id: 353,
        value: 'בית זיד',
        engName: 'BET ZEID'
    },
    {
        id: 710,
        value: 'בית זית',
        engName: 'BET ZAYIT'
    },
    {
        id: 143,
        value: 'בית זרע',
        engName: 'BET ZERA'
    },
    {
        id: 3575,
        value: 'בית חורון',
        engName: 'BET HORON'
    },
    {
        id: 877,
        value: 'בית חירות',
        engName: 'BET HERUT'
    },
    {
        id: 2033,
        value: 'בית חלקיה',
        engName: 'BET HILQIYYA'
    },
    {
        id: 159,
        value: 'בית חנן',
        engName: 'BET HANAN'
    },
    {
        id: 800,
        value: 'בית חנניה',
        engName: 'BET HANANYA'
    },
    {
        id: 1050,
        value: 'בית חשמונאי',
        engName: 'BET HASHMONAY'
    },
    {
        id: 288,
        value: 'בית יהושע',
        engName: 'BET YEHOSHUA'
    },
    {
        id: 265,
        value: 'בית יוסף',
        engName: 'BET YOSEF'
    },
    {
        id: 200,
        value: 'בית ינאי',
        engName: 'BET YANNAY'
    },
    {
        id: 326,
        value: 'בית יצחק-שער חפר',
        engName: 'BET YIZHAQ-SH. HEFER'
    },
    {
        id: 430,
        value: 'בית לחם הגלילית',
        engName: 'BET LEHEM HAGELILIT'
    },
    {
        id: 751,
        value: 'בית מאיר',
        engName: "BET ME'IR"
    },
    {
        id: 784,
        value: 'בית נחמיה',
        engName: 'BET NEHEMYA'
    },
    {
        id: 16,
        value: 'בית ניר',
        engName: 'BET NIR'
    },
    {
        id: 672,
        value: 'בית נקופה',
        engName: 'BET NEQOFA'
    },
    {
        id: 202,
        value: 'בית עובד',
        engName: 'BET OVED'
    },
    {
        id: 301,
        value: 'בית עוזיאל',
        engName: "BET UZZI'EL"
    },
    {
        id: 756,
        value: 'בית עזרא',
        engName: 'BET EZRA'
    },
    {
        id: 604,
        value: 'בית עריף',
        engName: 'BET ARIF'
    },
    {
        id: 212,
        value: 'בית צבי',
        engName: 'BET ZEVI'
    },
    {
        id: 598,
        value: 'בית קמה',
        engName: 'BET QAMA'
    },
    {
        id: 365,
        value: 'בית קשת',
        engName: 'BET QESHET'
    },
    {
        id: 848,
        value: 'בית רבן',
        engName: 'BET RABBAN'
    },
    {
        id: 1162,
        value: 'בית רימון',
        engName: 'BET RIMMON'
    },
    {
        id: 9200,
        value: 'בית שאן',
        engName: "BET SHE'AN"
    },
    {
        id: 2610,
        value: 'בית שמש',
        engName: 'BET SHEMESH'
    },
    {
        id: 248,
        value: 'בית שערים',
        engName: "BET SHE'ARIM"
    },
    {
        id: 747,
        value: 'בית שקמה',
        engName: 'BET SHIQMA'
    },
    {
        id: 252,
        value: 'ביתן אהרן',
        engName: 'BITAN AHARON'
    },
    {
        id: 3780,
        value: 'ביתר עילית',
        engName: 'BETAR ILLIT'
    },
    {
        id: 94,
        value: 'בלפוריה',
        engName: 'BALFURYA'
    },
    {
        id: 760,
        value: 'בן זכאי',
        engName: 'BEN ZAKKAY'
    },
    {
        id: 712,
        value: 'בן עמי',
        engName: 'BEN AMMI'
    },
    {
        id: 1084,
        value: 'בן שמן (כפר נוער)',
        engName: "BEN SHEMEN(K.NO'AR)"
    },
    {
        id: 2013,
        value: 'בן שמן (מושב)',
        engName: 'BEN SHEMEN (MOSHAV)'
    },
    {
        id: 6100,
        value: 'בני ברק',
        engName: 'BENE BERAQ'
    },
    {
        id: 1368,
        value: 'בני דקלים',
        engName: 'BNE DEQALIM'
    },
    {
        id: 592,
        value: 'בני דרום',
        engName: 'BENE DAROM'
    },
    {
        id: 386,
        value: 'בני דרור',
        engName: 'BENE DEROR'
    },
    {
        id: 4015,
        value: 'בני יהודה',
        engName: 'BENE YEHUDA'
    },
    {
        id: 1363,
        value: 'בני נצרים',
        engName: 'BNE NETSARIM'
    },
    {
        id: 448,
        value: 'בני עטרות',
        engName: 'BENE ATAROT'
    },
    {
        id: 1066,
        value: 'בני עי',
        engName: 'BENE AYISH'
    },
    {
        id: 418,
        value: 'בני ציון',
        engName: 'BENE ZIYYON'
    },
    {
        id: 588,
        value: 'בני ראם',
        engName: "BENE RE'EM"
    },
    {
        id: 685,
        value: 'בניה',
        engName: 'BENAYA'
    },
    {
        id: 9800,
        value: 'בנימינה-גבעת עדה*',
        engName: "BINYAMINA-GIV'AT ADA"
    },
    {
        id: 1326,
        value: 'בסמ',
        engName: 'BASMA'
    },
    {
        id: 944,
        value: 'בסמת טבעון',
        engName: "BASMAT TAB'UN"
    },
    {
        id: 483,
        value: 'בענה',
        engName: "BI'NE"
    },
    {
        id: 389,
        value: 'בצרה',
        engName: 'BAZRA'
    },
    {
        id: 589,
        value: 'בצת',
        engName: 'BEZET'
    },
    {
        id: 864,
        value: 'בקוע',
        engName: 'BEQOA'
    },
    {
        id: 3612,
        value: 'בקעות',
        engName: "BEQA'OT"
    },
    {
        id: 823,
        value: 'בר גיורא',
        engName: 'BAR GIYYORA'
    },
    {
        id: 1191,
        value: 'בר יוחאי',
        engName: 'BAR YOHAY'
    },
    {
        id: 3744,
        value: 'ברוכין',
        engName: 'BRUKHIN'
    },
    {
        id: 428,
        value: 'ברור חיל',
        engName: 'BEROR HAYIL'
    },
    {
        id: 2060,
        value: 'ברוש',
        engName: 'BEROSH'
    },
    {
        id: 3710,
        value: 'ברכה',
        engName: 'BERAKHA'
    },
    {
        id: 746,
        value: 'ברכיה',
        engName: 'BEREKHYA'
    },
    {
        id: 667,
        value: 'ברעם',
        engName: "BAR'AM"
    },
    {
        id: 141,
        value: 'ברק',
        engName: 'BARAQ'
    },
    {
        id: 617,
        value: 'ברקאי',
        engName: 'BARQAY'
    },
    {
        id: 3654,
        value: 'ברקן',
        engName: 'BARQAN'
    },
    {
        id: 2038,
        value: 'ברקת',
        engName: 'BAREQET'
    },
    {
        id: 1323,
        value: 'בת הדר',
        engName: 'BAT HADAR'
    },
    {
        id: 1361,
        value: 'בת חן',
        engName: 'BAT HEN'
    },
    {
        id: 1319,
        value: 'בת חפר',
        engName: 'BAT HEFER'
    },
    {
        id: 6200,
        value: 'בת ים',
        engName: 'BAT YAM'
    },
    {
        id: 3794,
        value: 'בת עין',
        engName: 'BAT AYIN'
    },
    {
        id: 33,
        value: 'בת שלמה',
        engName: 'BAT SHELOMO'
    },
    {
        id: 1292,
        value: "ג'דיידה-מכר",
        engName: 'JUDEIDE-MAKER'
    },
    {
        id: 485,
        value: "ג'ולס",
        engName: 'JULIS'
    },
    {
        id: 627,
        value: "ג'לג'וליה",
        engName: 'JALJULYE'
    },
    {
        id: 976,
        value: "ג'נאביב (שבט)",
        engName: 'JUNNABIB'
    },
    {
        id: 541,
        value: "ג'סר א-זרקא",
        engName: 'JISR AZ-ZARQA'
    },
    {
        id: 487,
        value: "ג'ש (גוש חלב)",
        engName: 'JISH(GUSH HALAV)'
    },
    {
        id: 628,
        value: "ג'ת",
        engName: 'JATT'
    },
    {
        id: 872,
        value: 'גאולי תימן',
        engName: "GE'ULE TEMAN"
    },
    {
        id: 379,
        value: 'גאולים',
        engName: "GE'ULIM"
    },
    {
        id: 853,
        value: 'גאליה',
        engName: "GE'ALYA"
    },
    {
        id: 352,
        value: 'גבולות',
        engName: 'GEVULOT'
    },
    {
        id: 424,
        value: 'גבים',
        engName: 'GEVIM'
    },
    {
        id: 86,
        value: 'גבע',
        engName: 'GEVA'
    },
    {
        id: 3763,
        value: 'גבע בנימין',
        engName: 'GEVA BINYAMIN'
    },
    {
        id: 683,
        value: 'גבע כרמל',
        engName: 'GEVA  KARMEL'
    },
    {
        id: 2014,
        value: 'גבעולים',
        engName: "GIV'OLIM"
    },
    {
        id: 3644,
        value: 'גבעון החדשה',
        engName: "GIV'ON HAHADASHA"
    },
    {
        id: 1344,
        value: 'גבעות בר',
        engName: "GEVA'OT BAR"
    },
    {
        id: 1293,
        value: 'גבעת אבני',
        engName: "GIV'AT AVNI"
    },
    {
        id: 1288,
        value: 'גבעת אלה',
        engName: "GIV'AT ELA"
    },
    {
        id: 147,
        value: 'גבעת ברנר',
        engName: "GIV'AT BRENNER"
    },
    {
        id: 870,
        value: 'גבעת השלושה',
        engName: "GIV'AT HASHELOSHA"
    },
    {
        id: 3730,
        value: 'גבעת זאב',
        engName: "GIV'AT ZE'EV"
    },
    {
        id: 207,
        value: 'גבעת ח',
        engName: "GIV'AT HEN"
    },
    {
        id: 2018,
        value: 'גבעת חיים (איחוד)',
        engName: "GIV'AT HAYYIM (IHUD)"
    },
    {
        id: 173,
        value: 'גבעת חיים (מאוחד)',
        engName: "GIV'AT HAYYIM(ME'UHAD)"
    },
    {
        id: 4021,
        value: 'גבעת יואב',
        engName: "GIV'AT YO'AV"
    },
    {
        id: 787,
        value: 'גבעת יערים',
        engName: "GIV'AT YE'ARIM"
    },
    {
        id: 919,
        value: 'גבעת ישעיהו',
        engName: "GIV'AT YESHA'YAHU"
    },
    {
        id: 802,
        value: 'גבעת כ',
        engName: "GIV'AT KOAH"
    },
    {
        id: 360,
        value: 'גבעת ניל',
        engName: "GIV'AT NILI"
    },
    {
        id: 703,
        value: 'גבעת עוז',
        engName: "GIV'AT OZ"
    },
    {
        id: 681,
        value: 'גבעת שמואל',
        engName: "GIV'AT SHEMU'EL"
    },
    {
        id: 566,
        value: 'גבעת שמש',
        engName: "GIV'AT SHEMESH"
    },
    {
        id: 1077,
        value: 'גבעת שפירא',
        engName: "GIV'AT SHAPPIRA"
    },
    {
        id: 793,
        value: 'גבעתי',
        engName: "GIV'ATI"
    },
    {
        id: 6300,
        value: 'גבעתיים',
        engName: "GIV'ATAYIM"
    },
    {
        id: 342,
        value: 'גברעם',
        engName: "GEVAR'AM"
    },
    {
        id: 133,
        value: 'גבת',
        engName: 'GEVAT'
    },
    {
        id: 35,
        value: 'גדות',
        engName: 'GADOT'
    },
    {
        id: 145,
        value: 'גדיש',
        engName: 'GADISH'
    },
    {
        id: 442,
        value: 'גדעונה',
        engName: "GID'ONA"
    },
    {
        id: 2550,
        value: 'גדרה',
        engName: 'GEDERA'
    },
    {
        id: 852,
        value: 'גונן',
        engName: 'GONEN'
    },
    {
        id: 755,
        value: 'גורן',
        engName: 'GOREN'
    },
    {
        id: 1219,
        value: 'גורנות הגליל',
        engName: 'GORNOT HAGALIL'
    },
    {
        id: 457,
        value: 'גזית',
        engName: 'GAZIT'
    },
    {
        id: 370,
        value: 'גזר',
        engName: 'GEZER'
    },
    {
        id: 706,
        value: 'גיאה',
        engName: "GE'A"
    },
    {
        id: 196,
        value: 'גיבתון',
        engName: 'GIBBETON'
    },
    {
        id: 1043,
        value: 'גיזו',
        engName: 'GIZO'
    },
    {
        id: 1204,
        value: 'גילון',
        engName: 'GILON'
    },
    {
        id: 736,
        value: 'גילת',
        engName: 'GILAT'
    },
    {
        id: 262,
        value: 'גינוסר',
        engName: 'GINNOSAR'
    },
    {
        id: 92,
        value: 'גיניגר',
        engName: 'GINNEGAR'
    },
    {
        id: 863,
        value: 'גינתון',
        engName: 'GINNATON'
    },
    {
        id: 1206,
        value: 'גיתה',
        engName: 'GITTA'
    },
    {
        id: 3613,
        value: 'גיתית',
        engName: 'GITTIT'
    },
    {
        id: 393,
        value: 'גלאון',
        engName: "GAL'ON"
    },
    {
        id: 3606,
        value: 'גלגל',
        engName: 'GILGAL'
    },
    {
        id: 346,
        value: 'גליל ים',
        engName: 'GELIL YAM'
    },
    {
        id: 369,
        value: 'גלעד (אבן יצחק)',
        engName: "GAL'ED (EVEN YIZHAQ)"
    },
    {
        id: 745,
        value: 'גמזו',
        engName: 'GIMZO'
    },
    {
        id: 1072,
        value: 'גן הדרום',
        engName: 'GAN HADAROM'
    },
    {
        id: 225,
        value: 'גן השומרון',
        engName: 'GAN HASHOMERON'
    },
    {
        id: 239,
        value: 'גן חיים',
        engName: 'GAN HAYYIM'
    },
    {
        id: 734,
        value: 'גן יאשיה',
        engName: 'GAN YOSHIYYA'
    },
    {
        id: 166,
        value: 'גן יבנה',
        engName: 'GAN YAVNE'
    },
    {
        id: 1274,
        value: 'גן נר',
        engName: 'GAN NER'
    },
    {
        id: 311,
        value: 'גן שורק',
        engName: 'GAN SOREQ'
    },
    {
        id: 144,
        value: 'גן שלמה',
        engName: 'GAN SHELOMO'
    },
    {
        id: 72,
        value: 'גן שמואל',
        engName: "GAN SHEMU'EL"
    },
    {
        id: 836,
        value: 'גנות',
        engName: 'GANNOT'
    },
    {
        id: 549,
        value: 'גנות הדר',
        engName: 'GANNOT HADAR'
    },
    {
        id: 1103,
        value: 'גני הדר',
        engName: 'GANNE HADAR'
    },
    {
        id: 1371,
        value: 'גני טל',
        engName: 'GANNE TAL'
    },
    {
        id: 862,
        value: 'גני יוחנן',
        engName: 'GANNE YOHANAN'
    },
    {
        id: 218,
        value: 'גני עם',
        engName: 'GANNE AM'
    },
    {
        id: 229,
        value: 'גני תקווה',
        engName: 'GANNE TIQWA'
    },
    {
        id: 842,
        value: 'געש',
        engName: "GA'ASH"
    },
    {
        id: 463,
        value: 'געתון',
        engName: "GA'TON"
    },
    {
        id: 39,
        value: 'גפן',
        engName: 'GEFEN'
    },
    {
        id: 1129,
        value: 'גרופית',
        engName: 'GEROFIT'
    },
    {
        id: 4022,
        value: 'גשור',
        engName: 'GESHUR'
    },
    {
        id: 305,
        value: 'גשר',
        engName: 'GESHER'
    },
    {
        id: 574,
        value: 'גשר הזיו',
        engName: 'GESHER HAZIW'
    },
    {
        id: 340,
        value: 'גת (קיבוץ)',
        engName: 'GAT(QIBBUZ)'
    },
    {
        id: 128,
        value: 'גת רימון',
        engName: 'GAT RIMMON'
    },
    {
        id: 494,
        value: 'דאלית אל-כרמל',
        engName: 'DALIYAT AL-KARMEL'
    },
    {
        id: 146,
        value: 'דבורה',
        engName: 'DEVORA'
    },
    {
        id: 489,
        value: 'דבורייה',
        engName: 'DABURIYYA'
    },
    {
        id: 849,
        value: 'דבירה',
        engName: 'DEVIRA'
    },
    {
        id: 407,
        value: 'דברת',
        engName: 'DAVERAT'
    },
    {
        id: 62,
        value: "דגניה א'",
        engName: 'DEGANYA ALEF'
    },
    {
        id: 79,
        value: "דגניה ב'",
        engName: 'DEGANYA BET'
    },
    {
        id: 1067,
        value: 'דוב',
        engName: 'DOVEV'
    },
    {
        id: 3747,
        value: 'דולב',
        engName: 'DOLEV'
    },
    {
        id: 738,
        value: 'דור',
        engName: 'DOR'
    },
    {
        id: 336,
        value: 'דורות',
        engName: 'DOROT'
    },
    {
        id: 475,
        value: 'דחי',
        engName: 'DAHI'
    },
    {
        id: 490,
        value: 'דייר אל-אסד',
        engName: 'DEIR AL-ASAD'
    },
    {
        id: 492,
        value: 'דייר חנא',
        engName: 'DEIR HANNA'
    },
    {
        id: 493,
        value: 'דייר ראפאת',
        engName: 'DEIR RAFAT'
    },
    {
        id: 2200,
        value: 'דימונה',
        engName: 'DIMONA'
    },
    {
        id: 2063,
        value: 'דישון',
        engName: 'DISHON'
    },
    {
        id: 300,
        value: 'דלייה',
        engName: 'DALIYYA'
    },
    {
        id: 431,
        value: 'דלתון',
        engName: 'DALTON'
    },
    {
        id: 1317,
        value: 'דמיידה',
        engName: 'DEMEIDE'
    },
    {
        id: 303,
        value: 'דן',
        engName: 'DAN'
    },
    {
        id: 302,
        value: 'דפנה',
        engName: 'DAFNA'
    },
    {
        id: 1241,
        value: 'דקל',
        engName: 'DEQEL'
    },
    {
        id: 1349,
        value: "דריג'את",
        engName: "DERIG'AT"
    },
    {
        id: 702,
        value: 'האון',
        engName: 'HAON'
    },
    {
        id: 675,
        value: 'הבונים',
        engName: 'HABONIM'
    },
    {
        id: 356,
        value: 'הגושרים',
        engName: 'HAGOSHERIM'
    },
    {
        id: 191,
        value: 'הדר עם',
        engName: 'HADAR AM'
    },
    {
        id: 9700,
        value: 'הוד השרון',
        engName: 'HOD HASHARON'
    },
    {
        id: 1322,
        value: 'הודיות',
        engName: 'HODAYOT'
    },
    {
        id: 726,
        value: 'הודייה',
        engName: 'HODIYYA'
    },
    {
        id: 1169,
        value: 'הוואשלה (שבט)',
        engName: 'HAWASHLA'
    },
    {
        id: 956,
        value: 'הוזייל (שבט)',
        engName: 'HUZAYYEL'
    },
    {
        id: 1186,
        value: 'הושעיה',
        engName: "HOSHA'AYA"
    },
    {
        id: 250,
        value: 'הזורע',
        engName: 'HAZOREA'
    },
    {
        id: 307,
        value: 'הזורעים',
        engName: "HAZORE'IM"
    },
    {
        id: 434,
        value: 'החותרים',
        engName: 'HAHOTERIM'
    },
    {
        id: 684,
        value: 'היוגב',
        engName: 'HAYOGEV'
    },
    {
        id: 1208,
        value: 'הילה',
        engName: 'HILLA'
    },
    {
        id: 377,
        value: 'המעפיל',
        engName: "HAMA'PIL"
    },
    {
        id: 677,
        value: 'הסוללים',
        engName: 'HASOLELIM'
    },
    {
        id: 423,
        value: 'העוגן',
        engName: 'HAOGEN'
    },
    {
        id: 3769,
        value: 'הר אדר',
        engName: 'HAR ADAR'
    },
    {
        id: 3603,
        value: 'הר גילה',
        engName: 'HAR GILLO'
    },
    {
        id: 1261,
        value: 'הר עמשא',
        engName: 'HAR AMASA'
    },
    {
        id: 464,
        value: 'הראל',
        engName: "HAR'EL"
    },
    {
        id: 1249,
        value: 'הרדוף',
        engName: 'HARDUF'
    },
    {
        id: 6400,
        value: 'הרצלייה',
        engName: 'HERZLIYA'
    },
    {
        id: 1203,
        value: 'הררית',
        engName: 'HARARIT'
    },
    {
        id: 3639,
        value: 'ורד יריחו',
        engName: 'WERED YERIHO'
    },
    {
        id: 1133,
        value: 'ורדון',
        engName: 'WARDON'
    },
    {
        id: 2742,
        value: 'זבארגה (שבט)',
        engName: 'ZABARGA'
    },
    {
        id: 815,
        value: 'זבדיאל',
        engName: "ZAVDI'EL"
    },
    {
        id: 44,
        value: 'זוהר',
        engName: 'ZOHAR'
    },
    {
        id: 584,
        value: 'זיקים',
        engName: 'ZIQIM'
    },
    {
        id: 788,
        value: 'זיתן',
        engName: 'ZETAN'
    },
    {
        id: 9300,
        value: 'זכרון יעקב',
        engName: "ZIKHRON YA'AQOV"
    },
    {
        id: 799,
        value: 'זכריה',
        engName: 'ZEKHARYA'
    },
    {
        id: 1290,
        value: 'זמר',
        engName: 'ZEMER'
    },
    {
        id: 1065,
        value: 'זמרת',
        engName: 'ZIMRAT'
    },
    {
        id: 816,
        value: 'זנוח',
        engName: 'ZANOAH'
    },
    {
        id: 2064,
        value: 'זרועה',
        engName: "ZERU'A"
    },
    {
        id: 975,
        value: 'זרזיר',
        engName: 'ZARZIR'
    },
    {
        id: 818,
        value: 'זרחיה',
        engName: 'ZERAHYA'
    },
    {
        id: 1321,
        value: "ח'ואלד",
        engName: 'KHAWALED'
    },
    {
        id: 986,
        value: "ח'ואלד (שבט)",
        engName: 'KHAWALED'
    },
    {
        id: 235,
        value: 'חבצלת השרון',
        engName: 'HAVAZZELET HASHARON'
    },
    {
        id: 1110,
        value: 'חבר',
        engName: 'HEVER'
    },
    {
        id: 717,
        value: 'חגור',
        engName: 'HAGOR'
    },
    {
        id: 3764,
        value: 'חגי',
        engName: 'HAGGAY'
    },
    {
        id: 205,
        value: 'חגלה',
        engName: 'HOGLA'
    },
    {
        id: 4026,
        value: 'חד-נס',
        engName: 'HAD-NES'
    },
    {
        id: 618,
        value: 'חדיד',
        engName: 'HADID'
    },
    {
        id: 6500,
        value: 'חדרה',
        engName: 'HADERA'
    },
    {
        id: 948,
        value: "חוג'ייראת (ד'הרה) (שבט)",
        engName: 'HUJEIRAT (DAHRA)'
    },
    {
        id: 160,
        value: 'חולדה',
        engName: 'HULDA'
    },
    {
        id: 6600,
        value: 'חולון',
        engName: 'HOLON'
    },
    {
        id: 1239,
        value: 'חולית',
        engName: 'HOLIT'
    },
    {
        id: 253,
        value: 'חולתה',
        engName: 'HULATA'
    },
    {
        id: 662,
        value: 'חוסן',
        engName: 'HOSEN'
    },
    {
        id: 1332,
        value: 'חוסנייה',
        engName: 'HUSSNIYYA'
    },
    {
        id: 115,
        value: 'חופית',
        engName: 'HOFIT'
    },
    {
        id: 374,
        value: 'חוקוק',
        engName: 'HUQOQ'
    },
    {
        id: 1303,
        value: 'חורה',
        engName: 'HURA'
    },
    {
        id: 496,
        value: 'חורפיש',
        engName: 'HURFEISH'
    },
    {
        id: 355,
        value: 'חורשים',
        engName: 'HORESHIM'
    },
    {
        id: 1047,
        value: 'חזון',
        engName: 'HAZON'
    },
    {
        id: 219,
        value: 'חיבת ציון',
        engName: 'HIBBAT ZIYYON'
    },
    {
        id: 3643,
        value: 'חיננית',
        engName: 'HINNANIT'
    },
    {
        id: 4000,
        value: 'חיפה',
        engName: 'HAIFA'
    },
    {
        id: 162,
        value: 'חירות',
        engName: 'HERUT'
    },
    {
        id: 1272,
        value: 'חלוץ',
        engName: 'HALUZ'
    },
    {
        id: 3573,
        value: 'חלמיש',
        engName: 'HALLAMISH'
    },
    {
        id: 820,
        value: 'חלץ',
        engName: 'HELEZ'
    },
    {
        id: 993,
        value: 'חמאם',
        engName: 'HAMAM'
    },
    {
        id: 801,
        value: 'חמד',
        engName: 'HEMED'
    },
    {
        id: 343,
        value: 'חמדיה',
        engName: 'HAMADYA'
    },
    {
        id: 3646,
        value: 'חמדת',
        engName: 'HEMDAT'
    },
    {
        id: 3609,
        value: 'חמרה',
        engName: 'HAMRA'
    },
    {
        id: 807,
        value: 'חניאל',
        engName: "HANNI'EL"
    },
    {
        id: 280,
        value: 'חניתה',
        engName: 'HANITA'
    },
    {
        id: 1257,
        value: 'חנתון',
        engName: 'HANNATON'
    },
    {
        id: 4005,
        value: 'חספין',
        engName: 'HASPIN'
    },
    {
        id: 363,
        value: 'חפץ חיים',
        engName: 'HAFEZ HAYYIM'
    },
    {
        id: 90,
        value: 'חפצי-בה',
        engName: 'HEFZI-BAH'
    },
    {
        id: 700,
        value: 'חצב',
        engName: 'HAZAV'
    },
    {
        id: 13,
        value: 'חצבה',
        engName: 'HAZEVA'
    },
    {
        id: 406,
        value: 'חצור-אשדוד',
        engName: 'HAZOR-ASHDOD'
    },
    {
        id: 2034,
        value: 'חצור הגלילית',
        engName: 'HAZOR HAGELILIT'
    },
    {
        id: 397,
        value: 'חצרים',
        engName: 'HAZERIM'
    },
    {
        id: 422,
        value: 'חרב לאת',
        engName: "HEREV LE'ET"
    },
    {
        id: 1024,
        value: 'חרוצים',
        engName: 'HARUZIM'
    },
    {
        id: 1247,
        value: 'חריש',
        engName: 'HARISH'
    },
    {
        id: 3717,
        value: 'חרמש',
        engName: 'HERMESH'
    },
    {
        id: 1209,
        value: 'חרשים',
        engName: 'HARASHIM'
    },
    {
        id: 3770,
        value: 'חשמונאים',
        engName: "HASHMONA'IM"
    },
    {
        id: 6700,
        value: 'טבריה',
        engName: 'TIBERIAS'
    },
    {
        id: 962,
        value: 'טובא-זנגרייה',
        engName: 'TUBA-ZANGARIYYE'
    },
    {
        id: 498,
        value: 'טורעאן',
        engName: "TUR'AN"
    },
    {
        id: 2730,
        value: 'טייבה',
        engName: 'TAYIBE'
    },
    {
        id: 497,
        value: 'טייבה (בעמק)',
        engName: 'TAYIBE(BAEMEQ)'
    },
    {
        id: 2720,
        value: 'טירה',
        engName: 'TIRE'
    },
    {
        id: 663,
        value: 'טירת יהודה',
        engName: 'TIRAT YEHUDA'
    },
    {
        id: 2100,
        value: 'טירת כרמל',
        engName: 'TIRAT KARMEL'
    },
    {
        id: 268,
        value: 'טירת צבי',
        engName: 'TIRAT ZEVI'
    },
    {
        id: 1181,
        value: 'טל-אל',
        engName: 'TAL-EL'
    },
    {
        id: 462,
        value: 'טל שחר',
        engName: 'TAL SHAHAR'
    },
    {
        id: 1177,
        value: 'טללים',
        engName: 'TELALIM'
    },
    {
        id: 3788,
        value: 'טלמון',
        engName: 'TALMON'
    },
    {
        id: 8900,
        value: 'טמרה',
        engName: 'TAMRA'
    },
    {
        id: 547,
        value: 'טמרה (יזרעאל)',
        engName: "TAMRA (YIZRE'EL)"
    },
    {
        id: 3743,
        value: 'טנא',
        engName: 'TENE'
    },
    {
        id: 1214,
        value: 'טפחות',
        engName: 'TEFAHOT'
    },
    {
        id: 1295,
        value: "יאנוח-ג'ת",
        engName: 'YANUH-JAT'
    },
    {
        id: 1232,
        value: 'יבול',
        engName: 'YEVUL'
    },
    {
        id: 46,
        value: 'יבנאל',
        engName: "YAVNE'EL"
    },
    {
        id: 2660,
        value: 'יבנה',
        engName: 'YAVNE'
    },
    {
        id: 96,
        value: 'יגור',
        engName: 'YAGUR'
    },
    {
        id: 798,
        value: 'יגל',
        engName: 'YAGEL'
    },
    {
        id: 577,
        value: 'יד בנימין',
        engName: 'YAD BINYAMIN'
    },
    {
        id: 1134,
        value: 'יד השמונה',
        engName: 'YAD HASHEMONA'
    },
    {
        id: 758,
        value: 'יד חנה',
        engName: 'YAD HANNA'
    },
    {
        id: 358,
        value: 'יד מרדכי',
        engName: 'YAD MORDEKHAY'
    },
    {
        id: 775,
        value: 'יד נתן',
        engName: 'YAD NATAN'
    },
    {
        id: 64,
        value: 'יד רמב',
        engName: 'YAD RAMBAM'
    },
    {
        id: 1144,
        value: 'ידידה',
        engName: 'YEDIDA'
    },
    {
        id: 9400,
        value: 'יהוד',
        engName: 'YEHUD'
    },
    {
        id: 1158,
        value: 'יהל',
        engName: 'YAHEL'
    },
    {
        id: 2009,
        value: 'יובל',
        engName: 'YUVAL'
    },
    {
        id: 1226,
        value: 'יובלים',
        engName: 'YUVALLIM'
    },
    {
        id: 1112,
        value: 'יודפת',
        engName: 'YODEFAT'
    },
    {
        id: 4007,
        value: 'יונתן',
        engName: 'YONATAN'
    },
    {
        id: 803,
        value: 'יושיביה',
        engName: 'YOSHIVYA'
    },
    {
        id: 452,
        value: 'יזרעאל',
        engName: "YIZRE'EL"
    },
    {
        id: 409,
        value: 'יחיעם',
        engName: "YEHI'AM"
    },
    {
        id: 866,
        value: 'יטבתה',
        engName: 'YOTVATA'
    },
    {
        id: 3607,
        value: 'ייט',
        engName: 'YITAV'
    },
    {
        id: 811,
        value: 'יכיני',
        engName: 'YAKHINI'
    },
    {
        id: 753,
        value: 'ינוב',
        engName: 'YANUV'
    },
    {
        id: 2011,
        value: 'ינון',
        engName: 'YINNON'
    },
    {
        id: 29,
        value: 'יסוד המעלה',
        engName: "YESUD HAMA'ALA"
    },
    {
        id: 440,
        value: 'יסודות',
        engName: 'YESODOT'
    },
    {
        id: 575,
        value: 'יסעור',
        engName: "YAS'UR"
    },
    {
        id: 1138,
        value: 'יעד',
        engName: "YA'AD"
    },
    {
        id: 1117,
        value: 'יעל',
        engName: "YA'EL"
    },
    {
        id: 1044,
        value: 'יעף',
        engName: "YE'AF"
    },
    {
        id: 795,
        value: 'יערה',
        engName: "YA'ARA"
    },
    {
        id: 499,
        value: 'יפיע',
        engName: 'YAFI'
    },
    {
        id: 3566,
        value: 'יפית',
        engName: 'YAFIT'
    },
    {
        id: 134,
        value: 'יפעת',
        engName: "YIF'AT"
    },
    {
        id: 453,
        value: 'יפתח',
        engName: 'YIFTAH'
    },
    {
        id: 3749,
        value: 'יצהר',
        engName: 'YIZHAR'
    },
    {
        id: 759,
        value: 'יציץ',
        engName: 'YAZIZ'
    },
    {
        id: 417,
        value: 'יקום',
        engName: 'YAQUM'
    },
    {
        id: 3647,
        value: 'יקיר',
        engName: 'YAQIR'
    },
    {
        id: 241,
        value: 'יקנעם (מושבה)',
        engName: "YOQNE'AM(MOSHAVA)"
    },
    {
        id: 240,
        value: 'יקנעם עילית',
        engName: "YOQNE'AM ILLIT"
    },
    {
        id: 623,
        value: 'יראון',
        engName: "YIR'ON"
    },
    {
        id: 2026,
        value: 'ירדנה',
        engName: 'YARDENA'
    },
    {
        id: 831,
        value: 'ירוחם',
        engName: 'YEROHAM'
    },
    {
        id: 3000,
        value: 'ירושלים',
        engName: 'JERUSALEM'
    },
    {
        id: 718,
        value: 'ירחיב',
        engName: 'YARHIV'
    },
    {
        id: 502,
        value: 'ירכא',
        engName: 'YIRKA'
    },
    {
        id: 183,
        value: 'ירקונה',
        engName: 'YARQONA'
    },
    {
        id: 916,
        value: 'ישע',
        engName: 'YESHA'
    },
    {
        id: 805,
        value: 'ישעי',
        engName: "YISH'I"
    },
    {
        id: 828,
        value: 'ישרש',
        engName: 'YASHRESH'
    },
    {
        id: 1227,
        value: 'יתד',
        engName: 'YATED'
    },
    {
        id: 504,
        value: 'כאבול',
        engName: 'KABUL'
    },
    {
        id: 505,
        value: "כאוכב אבו אל-היג'א",
        engName: 'KAOKAB ABU AL-HIJA'
    },
    {
        id: 576,
        value: 'כברי',
        engName: 'KABRI'
    },
    {
        id: 371,
        value: 'כדורי',
        engName: 'KADOORIE'
    },
    {
        id: 1338,
        value: 'כדיתה',
        engName: 'KADDITA'
    },
    {
        id: 3564,
        value: 'כוכב השחר',
        engName: 'KOKHAV HASHAHAR'
    },
    {
        id: 1224,
        value: 'כוכב יאיר',
        engName: "KOKHAV YA'IR"
    },
    {
        id: 3779,
        value: 'כוכב יעקב',
        engName: "KOKHAV YA'AQOV"
    },
    {
        id: 824,
        value: 'כוכב מיכאל',
        engName: "KOKHAV MIKHA'EL"
    },
    {
        id: 1252,
        value: 'כורזים',
        engName: 'KORAZIM'
    },
    {
        id: 1210,
        value: 'כחל',
        engName: 'KAHAL'
    },
    {
        id: 1367,
        value: 'כחלה',
        engName: 'KOCHLEA'
    },
    {
        id: 840,
        value: 'כיסופים',
        engName: 'KISSUFIM'
    },
    {
        id: 1153,
        value: 'כישור',
        engName: 'KISHOR'
    },
    {
        id: 1183,
        value: 'כליל',
        engName: 'KELIL'
    },
    {
        id: 1229,
        value: 'כלנית',
        engName: 'KALLANIT'
    },
    {
        id: 1331,
        value: 'כמאנה',
        engName: 'KAMANE'
    },
    {
        id: 1291,
        value: 'כמהין',
        engName: 'KEMEHIN'
    },
    {
        id: 1201,
        value: 'כמון',
        engName: 'KAMMON'
    },
    {
        id: 2006,
        value: 'כנות',
        engName: 'KANNOT'
    },
    {
        id: 4028,
        value: 'כנף',
        engName: 'KANAF'
    },
    {
        id: 63,
        value: 'כנרת (מושבה)',
        engName: 'KINNERET(MOSHAVA)'
    },
    {
        id: 57,
        value: 'כנרת (קבוצה)',
        engName: 'KINNERET(QEVUZA)'
    },
    {
        id: 1059,
        value: 'כסיפה',
        engName: 'KUSEIFE'
    },
    {
        id: 859,
        value: 'כסלון',
        engName: 'KESALON'
    },
    {
        id: 1296,
        value: 'כסרא-סמיע',
        engName: 'KISRA-SUMEI'
    },
    {
        id: 978,
        value: "כעביה-טבאש-חג'אג'רה",
        engName: "KA'ABIYYE-TABBASH-HAJAJRE"
    },
    {
        id: 857,
        value: 'כפר אביב',
        engName: 'KEFAR AVIV'
    },
    {
        id: 3638,
        value: 'כפר אדומים',
        engName: 'KEFAR ADUMMIM'
    },
    {
        id: 364,
        value: 'כפר אוריה',
        engName: 'KEFAR URIYYA'
    },
    {
        id: 690,
        value: 'כפר אחים',
        engName: 'KEFAR AHIM'
    },
    {
        id: 220,
        value: 'כפר ביאליק',
        engName: 'KEFAR BIALIK'
    },
    {
        id: 177,
        value: 'כפר ביל',
        engName: 'KEFAR BILU'
    },
    {
        id: 357,
        value: 'כפר בלום',
        engName: 'KEFAR BLUM'
    },
    {
        id: 2010,
        value: 'כפר בן נון',
        engName: 'KEFAR BIN NUN'
    },
    {
        id: 633,
        value: 'כפר ברא',
        engName: 'KAFAR BARA'
    },
    {
        id: 132,
        value: 'כפר ברוך',
        engName: 'KEFAR BARUKH'
    },
    {
        id: 106,
        value: 'כפר גדעון',
        engName: "KEFAR GID'ON"
    },
    {
        id: 427,
        value: 'כפר גלים',
        engName: 'KEFAR GALLIM'
    },
    {
        id: 310,
        value: 'כפר גליקסון',
        engName: 'KEFAR GLIKSON'
    },
    {
        id: 76,
        value: 'כפר גלעדי',
        engName: "KEFAR GIL'ADI"
    },
    {
        id: 707,
        value: 'כפר דניאל',
        engName: 'KEFAR DANIYYEL'
    },
    {
        id: 3796,
        value: 'כפר האורנים',
        engName: 'KEFAR HAORANIM'
    },
    {
        id: 192,
        value: 'כפר החורש',
        engName: 'KEFAR HAHORESH'
    },
    {
        id: 254,
        value: 'כפר המכבי',
        engName: 'KEFAR HAMAKKABBI'
    },
    {
        id: 582,
        value: 'כפר הנגיד',
        engName: 'KEFAR HANAGID'
    },
    {
        id: 890,
        value: 'כפר הנוער הדתי',
        engName: "KEFAR HANO'AR HADATI"
    },
    {
        id: 443,
        value: 'כפר הנשיא',
        engName: 'KEFAR HANASI'
    },
    {
        id: 187,
        value: 'כפר הס',
        engName: 'KEFAR HESS'
    },
    {
        id: 217,
        value: 'כפר הרא',
        engName: "KEFAR HARO'E"
    },
    {
        id: 888,
        value: 'כפר הרי',
        engName: 'KEFAR HARIF'
    },
    {
        id: 190,
        value: 'כפר ויתקין',
        engName: 'KEFAR VITKIN'
    },
    {
        id: 320,
        value: 'כפר ורבורג',
        engName: 'KEFAR WARBURG'
    },
    {
        id: 1263,
        value: 'כפר ורדים',
        engName: 'KEFAR WERADIM'
    },
    {
        id: 1325,
        value: 'כפר זוהרים',
        engName: 'KEFAR ZOHARIM'
    },
    {
        id: 786,
        value: 'כפר זיתים',
        engName: 'KEFAR ZETIM'
    },
    {
        id: 696,
        value: 'כפר חב',
        engName: 'KEFAR HABAD'
    },
    {
        id: 609,
        value: 'כפר חושן',
        engName: 'KEFAR HOSHEN'
    },
    {
        id: 255,
        value: 'כפר חיטים',
        engName: 'KEFAR HITTIM'
    },
    {
        id: 193,
        value: 'כפר חיים',
        engName: 'KEFAR HAYYIM'
    },
    {
        id: 1297,
        value: 'כפר חנניה',
        engName: 'KEFAR HANANYA'
    },
    {
        id: 112,
        value: "כפר חסידים א'",
        engName: 'KEFAR HASIDIM ALEF'
    },
    {
        id: 889,
        value: "כפר חסידים ב'",
        engName: 'KEFAR HASIDIM BET'
    },
    {
        id: 4004,
        value: 'כפר חרוב',
        engName: 'KEFAR HARUV'
    },
    {
        id: 673,
        value: 'כפר טרומן',
        engName: 'KEFAR TRUMAN'
    },
    {
        id: 507,
        value: 'כפר יאסיף',
        engName: 'KAFAR YASIF'
    },
    {
        id: 233,
        value: 'כפר ידידיה',
        engName: 'KEFAR YEDIDYA'
    },
    {
        id: 140,
        value: 'כפר יהושע',
        engName: 'KEFAR YEHOSHUA'
    },
    {
        id: 168,
        value: 'כפר יונה',
        engName: 'KEFAR YONA'
    },
    {
        id: 85,
        value: 'כפר יחזקאל',
        engName: 'KEFAR YEHEZQEL'
    },
    {
        id: 170,
        value: 'כפר יעבץ',
        engName: "KEFAR YA'BEZ"
    },
    {
        id: 508,
        value: 'כפר כמא',
        engName: 'KAFAR KAMA'
    },
    {
        id: 509,
        value: 'כפר כנא',
        engName: 'KAFAR KANNA'
    },
    {
        id: 387,
        value: 'כפר מונש',
        engName: 'KEFAR MONASH'
    },
    {
        id: 1095,
        value: 'כפר מימון',
        engName: 'KEFAR MAYMON'
    },
    {
        id: 98,
        value: 'כפר מל',
        engName: 'KEFAR MALAL'
    },
    {
        id: 510,
        value: 'כפר מנדא',
        engName: 'KAFAR MANDA'
    },
    {
        id: 274,
        value: 'כפר מנחם',
        engName: 'KEFAR MENAHEM'
    },
    {
        id: 297,
        value: 'כפר מסריק',
        engName: 'KEFAR MASARYK'
    },
    {
        id: 512,
        value: 'כפר מצר',
        engName: 'KAFAR MISR'
    },
    {
        id: 764,
        value: 'כפר מרדכי',
        engName: 'KEFAR MORDEKHAY'
    },
    {
        id: 316,
        value: 'כפר נטר',
        engName: 'KEFAR NETTER'
    },
    {
        id: 345,
        value: 'כפר סאלד',
        engName: 'KEFAR SZOLD'
    },
    {
        id: 6900,
        value: 'כפר סבא',
        engName: 'KEFAR SAVA'
    },
    {
        id: 107,
        value: 'כפר סילבר',
        engName: 'KEFAR SILVER'
    },
    {
        id: 249,
        value: 'כפר סירקין',
        engName: 'KEFAR SIRKIN'
    },
    {
        id: 875,
        value: 'כפר עבודה',
        engName: 'KEFAR AVODA'
    },
    {
        id: 845,
        value: 'כפר עזה',
        engName: 'KEFAR AZZA'
    },
    {
        id: 3488,
        value: 'כפר עציון',
        engName: 'KEFAR EZYON'
    },
    {
        id: 189,
        value: 'כפר פינס',
        engName: 'KEFAR PINES'
    },
    {
        id: 634,
        value: 'כפר קאסם',
        engName: 'KAFAR QASEM'
    },
    {
        id: 388,
        value: 'כפר קיש',
        engName: 'KEFAR KISCH'
    },
    {
        id: 654,
        value: 'כפר קרע',
        engName: 'KAFAR QARA'
    },
    {
        id: 579,
        value: 'כפר ראש הנקרה',
        engName: 'KEFAR ROSH HANIQRA'
    },
    {
        id: 1130,
        value: 'כפר רוזנואלד (זרעית)',
        engName: 'KEFAR ROZENWALD(ZARIT)'
    },
    {
        id: 295,
        value: 'כפר רופין',
        engName: 'KEFAR RUPPIN'
    },
    {
        id: 1166,
        value: 'כפר רות',
        engName: 'KEFAR RUT'
    },
    {
        id: 605,
        value: 'כפר שמאי',
        engName: 'KEFAR SHAMMAY'
    },
    {
        id: 743,
        value: 'כפר שמואל',
        engName: "KEFAR SHEMU'EL"
    },
    {
        id: 267,
        value: 'כפר שמריהו',
        engName: 'KEFAR SHEMARYAHU'
    },
    {
        id: 47,
        value: 'כפר תבור',
        engName: 'KEFAR TAVOR'
    },
    {
        id: 3572,
        value: 'כפר תפוח',
        engName: 'KEFAR TAPPUAH'
    },
    {
        id: 1285,
        value: 'כרכום',
        engName: 'KARKOM'
    },
    {
        id: 664,
        value: 'כרם בן זמרה',
        engName: 'KEREM BEN ZIMRA'
    },
    {
        id: 88,
        value: 'כרם בן שמן',
        engName: 'KEREM BEN SHEMEN'
    },
    {
        id: 1094,
        value: 'כרם יבנה (ישיבה)',
        engName: 'KEREM YAVNE(YESHIVA)'
    },
    {
        id: 580,
        value: 'כרם מהר',
        engName: 'KEREM MAHARAL'
    },
    {
        id: 1085,
        value: 'כרם שלום',
        engName: 'KEREM SHALOM'
    },
    {
        id: 1264,
        value: 'כרמי יוסף',
        engName: 'KARME YOSEF'
    },
    {
        id: 3766,
        value: 'כרמי צור',
        engName: 'KARME ZUR'
    },
    {
        id: 1139,
        value: 'כרמיאל',
        engName: "KARMI'EL"
    },
    {
        id: 768,
        value: 'כרמייה',
        engName: 'KARMIYYA'
    },
    {
        id: 1198,
        value: 'כרמים',
        engName: 'KERAMIM'
    },
    {
        id: 3656,
        value: 'כרמל',
        engName: 'KARMEL'
    },
    {
        id: 1207,
        value: 'לבון',
        engName: 'LAVON'
    },
    {
        id: 585,
        value: 'לביא',
        engName: 'LAVI'
    },
    {
        id: 1230,
        value: 'לבנים',
        engName: 'LIVNIM'
    },
    {
        id: 2023,
        value: 'להב',
        engName: 'LAHAV'
    },
    {
        id: 380,
        value: 'להבות הבשן',
        engName: 'LAHAVOT HABASHAN'
    },
    {
        id: 715,
        value: 'להבות חביבה',
        engName: 'LAHAVOT HAVIVA'
    },
    {
        id: 1271,
        value: 'להבים',
        engName: 'LEHAVIM'
    },
    {
        id: 7000,
        value: 'לוד',
        engName: 'LOD'
    },
    {
        id: 52,
        value: 'לוזית',
        engName: 'LUZIT'
    },
    {
        id: 595,
        value: 'לוחמי הגיטאות',
        engName: "LOHAME HAGETA'OT"
    },
    {
        id: 1171,
        value: 'לוטם',
        engName: 'LOTEM'
    },
    {
        id: 1255,
        value: 'לוטן',
        engName: 'LOTAN'
    },
    {
        id: 674,
        value: 'לימן',
        engName: 'LIMAN'
    },
    {
        id: 24,
        value: 'לכיש',
        engName: 'LAKHISH'
    },
    {
        id: 1310,
        value: 'לפיד',
        engName: 'LAPPID'
    },
    {
        id: 1173,
        value: 'לפידות',
        engName: 'LAPPIDOT'
    },
    {
        id: 1060,
        value: 'לקיה',
        engName: 'LAQYE'
    },
    {
        id: 2055,
        value: 'מאור',
        engName: "MA'OR"
    },
    {
        id: 102,
        value: 'מאיר שפיה',
        engName: "ME'IR SHEFEYA"
    },
    {
        id: 771,
        value: 'מבוא ביתר',
        engName: 'MEVO BETAR'
    },
    {
        id: 3569,
        value: 'מבוא דותן',
        engName: 'MEVO DOTAN'
    },
    {
        id: 3709,
        value: 'מבוא חורון',
        engName: 'MEVO HORON'
    },
    {
        id: 4204,
        value: 'מבוא חמה',
        engName: 'MEVO HAMMA'
    },
    {
        id: 1141,
        value: 'מבוא מודיעים',
        engName: "MEVO MODI'IM"
    },
    {
        id: 1318,
        value: 'מבואות ים',
        engName: "MEVO'OT YAM"
    },
    {
        id: 1080,
        value: 'מבועים',
        engName: "MABBU'IM"
    },
    {
        id: 829,
        value: 'מבטחים',
        engName: 'MIVTAHIM'
    },
    {
        id: 573,
        value: 'מבקיעים',
        engName: "MAVQI'IM"
    },
    {
        id: 1015,
        value: 'מבשרת ציון',
        engName: 'MEVASSERET ZIYYON'
    },
    {
        id: 516,
        value: "מג'ד אל-כרום",
        engName: 'MAJD AL-KURUM'
    },
    {
        id: 4201,
        value: "מג'דל שמס",
        engName: 'MAJDAL SHAMS'
    },
    {
        id: 481,
        value: 'מגאר',
        engName: 'MUGHAR'
    },
    {
        id: 689,
        value: 'מגדים',
        engName: 'MEGADIM'
    },
    {
        id: 65,
        value: 'מגדל',
        engName: 'MIGDAL'
    },
    {
        id: 874,
        value: 'מגדל העמק',
        engName: 'MIGDAL HAEMEQ'
    },
    {
        id: 3561,
        value: 'מגדל עוז',
        engName: 'MIGDAL OZ'
    },
    {
        id: 3751,
        value: 'מגדלים',
        engName: 'MIGDALIM'
    },
    {
        id: 586,
        value: 'מגידו',
        engName: 'MEGIDDO'
    },
    {
        id: 375,
        value: 'מגל',
        engName: 'MAGGAL'
    },
    {
        id: 695,
        value: 'מגן',
        engName: 'MAGEN'
    },
    {
        id: 1155,
        value: 'מגן שאול',
        engName: "MAGEN SHA'UL"
    },
    {
        id: 722,
        value: 'מגשימים',
        engName: 'MAGSHIMIM'
    },
    {
        id: 2029,
        value: 'מדרך עוז',
        engName: 'MIDRAKH OZ'
    },
    {
        id: 1140,
        value: 'מדרשת בן גוריון',
        engName: 'MIDRESHET BEN GURION'
    },
    {
        id: 897,
        value: 'מדרשת רופין',
        engName: 'MIDRESHET RUPPIN'
    },
    {
        id: 1200,
        value: 'מודיעין-מכבים-רעות*',
        engName: "MODI'IN-MAKKABBIM-RE'UT"
    },
    {
        id: 3797,
        value: 'מודיעין עילית',
        engName: "MODI'IN ILLIT"
    },
    {
        id: 1360,
        value: 'מולדה*',
        engName: 'MOLADA*'
    },
    {
        id: 269,
        value: 'מולדת',
        engName: 'MOLEDET'
    },
    {
        id: 208,
        value: 'מוצא עילית',
        engName: 'MOZA ILLIT'
    },
    {
        id: 635,
        value: 'מוקייבלה',
        engName: 'MUQEIBLE'
    },
    {
        id: 1163,
        value: 'מורן',
        engName: 'MORAN'
    },
    {
        id: 1178,
        value: 'מורשת',
        engName: 'MORESHET'
    },
    {
        id: 606,
        value: 'מזור',
        engName: 'MAZOR'
    },
    {
        id: 28,
        value: 'מזכרת בתיה',
        engName: 'MAZKERET BATYA'
    },
    {
        id: 104,
        value: 'מזרע',
        engName: 'MIZRA'
    },
    {
        id: 517,
        value: 'מזרעה',
        engName: "MAZRA'A"
    },
    {
        id: 3599,
        value: 'מחולה',
        engName: 'MEHOLA'
    },
    {
        id: 1411,
        value: 'מחנה הילה*',
        engName: 'MAHANE HILLA'
    },
    {
        id: 1418,
        value: 'מחנה טלי*',
        engName: 'MAHANE TALI'
    },
    {
        id: 1413,
        value: 'מחנה יהודית*',
        engName: 'MAHANE YEHUDIT'
    },
    {
        id: 1416,
        value: 'מחנה יוכבד*',
        engName: 'MAHANE YOKHEVED'
    },
    {
        id: 1415,
        value: 'מחנה יפה*',
        engName: 'MAHANE YAFA'
    },
    {
        id: 1196,
        value: 'מחנה יתיר',
        engName: 'MAHANE YATTIR'
    },
    {
        id: 1414,
        value: 'מחנה מרים*',
        engName: 'MAHANE MIRYAM'
    },
    {
        id: 1417,
        value: 'מחנה עדי*',
        engName: 'MAHANE ADI'
    },
    {
        id: 1412,
        value: 'מחנה תל נוף*',
        engName: 'MAHANE TEL NOF'
    },
    {
        id: 308,
        value: 'מחניים',
        engName: 'MAHANAYIM'
    },
    {
        id: 776,
        value: 'מחסיה',
        engName: 'MAHSEYA'
    },
    {
        id: 43,
        value: 'מטולה',
        engName: 'METULA'
    },
    {
        id: 822,
        value: 'מטע',
        engName: 'MATTA'
    },
    {
        id: 1128,
        value: 'מי עמי',
        engName: 'ME AMMI'
    },
    {
        id: 2054,
        value: 'מיטב',
        engName: 'METAV'
    },
    {
        id: 649,
        value: 'מייסר',
        engName: 'MEISER'
    },
    {
        id: 4019,
        value: 'מיצר',
        engName: 'MEZAR'
    },
    {
        id: 1282,
        value: 'מירב',
        engName: 'MERAV'
    },
    {
        id: 607,
        value: 'מירון',
        engName: 'MERON'
    },
    {
        id: 731,
        value: 'מישר',
        engName: 'MESHAR'
    },
    {
        id: 1268,
        value: 'מיתר',
        engName: 'METAR'
    },
    {
        id: 3614,
        value: 'מכורה',
        engName: 'MEKHORA'
    },
    {
        id: 1343,
        value: 'מכחול',
        engName: 'MAKCHUL'
    },
    {
        id: 382,
        value: 'מכמורת',
        engName: 'MIKHMORET'
    },
    {
        id: 1202,
        value: 'מכמנים',
        engName: 'MIKHMANNIM'
    },
    {
        id: 164,
        value: 'מלאה',
        engName: "MELE'A"
    },
    {
        id: 2044,
        value: 'מלילות',
        engName: 'MELILOT'
    },
    {
        id: 596,
        value: 'מלכייה',
        engName: 'MALKIYYA'
    },
    {
        id: 1154,
        value: 'מלכישוע',
        engName: 'MALKISHUA'
    },
    {
        id: 2030,
        value: 'מנוחה',
        engName: 'MENUHA'
    },
    {
        id: 1174,
        value: 'מנוף',
        engName: 'MANOF'
    },
    {
        id: 1205,
        value: 'מנות',
        engName: 'MANOT'
    },
    {
        id: 48,
        value: 'מנחמיה',
        engName: 'MENAHEMYA'
    },
    {
        id: 347,
        value: 'מנרה',
        engName: 'MENARA'
    },
    {
        id: 994,
        value: 'מנשית זבדה',
        engName: 'MANSHIYYET ZABDA'
    },
    {
        id: 1258,
        value: 'מסד',
        engName: 'MASSAD'
    },
    {
        id: 263,
        value: 'מסדה',
        engName: 'MASSADA'
    },
    {
        id: 298,
        value: 'מסילות',
        engName: 'MESILLOT'
    },
    {
        id: 742,
        value: 'מסילת ציון',
        engName: 'MESILLAT ZIYYON'
    },
    {
        id: 748,
        value: 'מסלול',
        engName: 'MASLUL'
    },
    {
        id: 4203,
        value: 'מסעדה',
        engName: "MAS'ADE"
    },
    {
        id: 939,
        value: 'מסעודין אל-עזאזמה (שבט)',
        engName: "MAS'UDIN AL-'AZAZME"
    },
    {
        id: 197,
        value: 'מעברות',
        engName: "MA'BAROT"
    },
    {
        id: 1082,
        value: 'מעגלים',
        engName: "MA'GALIM"
    },
    {
        id: 678,
        value: 'מעגן',
        engName: "MA'AGAN"
    },
    {
        id: 694,
        value: 'מעגן מיכאל',
        engName: "MA'AGAN MIKHA'EL"
    },
    {
        id: 272,
        value: 'מעוז חיים',
        engName: "MA'OZ HAYYIM"
    },
    {
        id: 3657,
        value: 'מעון',
        engName: "MA'ON"
    },
    {
        id: 570,
        value: 'מעונה',
        engName: "ME'ONA"
    },
    {
        id: 416,
        value: 'מעיין ברוך',
        engName: "MA'YAN BARUKH"
    },
    {
        id: 290,
        value: 'מעיין צבי',
        engName: "MA'YAN ZEVI"
    },
    {
        id: 518,
        value: 'מעיליא',
        engName: "MI'ELYA"
    },
    {
        id: 3616,
        value: 'מעלה אדומים',
        engName: "MA'ALE ADUMMIM"
    },
    {
        id: 3608,
        value: 'מעלה אפרים',
        engName: "MA'ALE EFRAYIM"
    },
    {
        id: 1127,
        value: 'מעלה גלבוע',
        engName: "MA'ALE GILBOA"
    },
    {
        id: 4008,
        value: 'מעלה גמלא',
        engName: "MA'ALE GAMLA"
    },
    {
        id: 286,
        value: 'מעלה החמישה',
        engName: "MA'ALE HAHAMISHA"
    },
    {
        id: 3752,
        value: 'מעלה לבונה',
        engName: "MA'ALE LEVONA"
    },
    {
        id: 3651,
        value: 'מעלה מכמש',
        engName: "MA'ALE MIKHMAS"
    },
    {
        id: 1327,
        value: 'מעלה עירון',
        engName: "MA'ALE IRON"
    },
    {
        id: 3653,
        value: 'מעלה עמוס',
        engName: "MA'ALE AMOS"
    },
    {
        id: 3637,
        value: 'מעלה שומרון',
        engName: "MA'ALE SHOMERON"
    },
    {
        id: 1063,
        value: 'מעלות-תרשיחא',
        engName: "MA'ALOT-TARSHIHA"
    },
    {
        id: 344,
        value: 'מענית',
        engName: "MA'ANIT"
    },
    {
        id: 230,
        value: 'מעש',
        engName: "MA'AS"
    },
    {
        id: 668,
        value: 'מפלסים',
        engName: 'MEFALLESIM'
    },
    {
        id: 3745,
        value: 'מצדות יהודה',
        engName: 'MEZADOT YEHUDA'
    },
    {
        id: 325,
        value: 'מצובה',
        engName: 'MAZZUVA'
    },
    {
        id: 757,
        value: 'מצליח',
        engName: 'MAZLIAH'
    },
    {
        id: 58,
        value: 'מצפה',
        engName: 'MIZPA'
    },
    {
        id: 1222,
        value: 'מצפה אבי',
        engName: 'MIZPE AVIV'
    },
    {
        id: 1370,
        value: 'מצפה אילן',
        engName: 'MITSPE ILAN'
    },
    {
        id: 3576,
        value: 'מצפה יריחו',
        engName: 'MIZPE YERIHO'
    },
    {
        id: 1190,
        value: 'מצפה נטופה',
        engName: 'MIZPE NETOFA'
    },
    {
        id: 99,
        value: 'מצפה רמון',
        engName: 'MIZPE RAMON'
    },
    {
        id: 3610,
        value: 'מצפה שלם',
        engName: 'MIZPE SHALEM'
    },
    {
        id: 648,
        value: 'מצר',
        engName: 'MEZER'
    },
    {
        id: 22,
        value: 'מקווה ישראל',
        engName: "MIQWE YISRA'EL"
    },
    {
        id: 843,
        value: 'מרגליות',
        engName: 'MARGALIYYOT'
    },
    {
        id: 4101,
        value: 'מרום גולן',
        engName: 'MEROM GOLAN'
    },
    {
        id: 1340,
        value: 'מרחב עם',
        engName: 'MERHAV AM'
    },
    {
        id: 97,
        value: 'מרחביה (מושב)',
        engName: 'MERHAVYA(MOSHAV)'
    },
    {
        id: 66,
        value: 'מרחביה (קיבוץ)',
        engName: 'MERHAVYA(QIBBUZ)'
    },
    {
        id: 1098,
        value: 'מרכז שפירא',
        engName: 'MERKAZ SHAPPIRA'
    },
    {
        id: 421,
        value: 'משאבי שדה',
        engName: "MASH'ABBE SADE"
    },
    {
        id: 765,
        value: 'משגב דב',
        engName: 'MISGAV DOV'
    },
    {
        id: 378,
        value: 'משגב עם',
        engName: 'MISGAV AM'
    },
    {
        id: 520,
        value: 'משהד',
        engName: 'MESHHED'
    },
    {
        id: 3605,
        value: 'משואה',
        engName: "MASSU'A"
    },
    {
        id: 620,
        value: 'משואות יצחק',
        engName: "MASSU'OT YIZHAQ"
    },
    {
        id: 3785,
        value: 'משכיות',
        engName: 'MASKIYYOT'
    },
    {
        id: 670,
        value: 'משמר איילון',
        engName: 'MISHMAR AYYALON'
    },
    {
        id: 563,
        value: 'משמר דוד',
        engName: 'MISHMAR DAWID'
    },
    {
        id: 732,
        value: 'משמר הירדן',
        engName: 'MISHMAR HAYARDEN'
    },
    {
        id: 395,
        value: 'משמר הנגב',
        engName: 'MISHMAR HANEGEV'
    },
    {
        id: 130,
        value: 'משמר העמק',
        engName: 'MISHMAR HAEMEQ'
    },
    {
        id: 729,
        value: 'משמר השבעה',
        engName: "MISHMAR HASHIV'A"
    },
    {
        id: 194,
        value: 'משמר השרון',
        engName: 'MISHMAR HASHARON'
    },
    {
        id: 213,
        value: 'משמרות',
        engName: 'MISHMAROT'
    },
    {
        id: 425,
        value: 'משמרת',
        engName: 'MISHMERET'
    },
    {
        id: 791,
        value: 'משען',
        engName: "MASH'EN"
    },
    {
        id: 1315,
        value: 'מתן',
        engName: 'MATTAN'
    },
    {
        id: 1184,
        value: 'מתת',
        engName: 'MATTAT'
    },
    {
        id: 3648,
        value: 'מתתיהו',
        engName: 'MATTITYAHU'
    },
    {
        id: 4551,
        value: 'נאות גולן',
        engName: "NE'OT GOLAN"
    },
    {
        id: 1124,
        value: 'נאות הכיכר',
        engName: "NE'OT HAKIKKAR"
    },
    {
        id: 408,
        value: 'נאות מרדכי',
        engName: "NE'OT MORDEKHAY"
    },
    {
        id: 1197,
        value: 'נאות סמדר',
        engName: "NE'OT SMADAR"
    },
    {
        id: 524,
        value: 'נאעורה',
        engName: "NA'URA"
    },
    {
        id: 396,
        value: 'נבטים',
        engName: 'NEVATIM'
    },
    {
        id: 315,
        value: 'נגבה',
        engName: 'NEGBA'
    },
    {
        id: 3724,
        value: 'נגוהות',
        engName: 'NEGOHOT'
    },
    {
        id: 309,
        value: 'נהורה',
        engName: 'NEHORA'
    },
    {
        id: 80,
        value: 'נהלל',
        engName: 'NAHALAL'
    },
    {
        id: 9100,
        value: 'נהרייה',
        engName: 'NAHARIYYA'
    },
    {
        id: 4304,
        value: 'נוב',
        engName: 'NOV'
    },
    {
        id: 55,
        value: 'נוגה',
        engName: 'NOGAH'
    },
    {
        id: 1366,
        value: 'נווה',
        engName: 'NAVE'
    },
    {
        id: 926,
        value: 'נווה אבות',
        engName: 'NEWE AVOT'
    },
    {
        id: 590,
        value: 'נווה אור',
        engName: 'NEWE UR'
    },
    {
        id: 4303,
        value: 'נווה אטי',
        engName: 'NEWE ATIV'
    },
    {
        id: 405,
        value: 'נווה אילן',
        engName: 'NEWE ILAN'
    },
    {
        id: 296,
        value: 'נווה איתן',
        engName: 'NEWE ETAN'
    },
    {
        id: 3725,
        value: 'נווה דניאל',
        engName: 'NEWE DANIYYEL'
    },
    {
        id: 1057,
        value: 'נווה זוהר',
        engName: 'NEWE ZOHAR'
    },
    {
        id: 1314,
        value: 'נווה זיו',
        engName: 'NEWE ZIV'
    },
    {
        id: 1279,
        value: 'נווה חריף',
        engName: 'NEWE HARIF'
    },
    {
        id: 312,
        value: 'נווה ים',
        engName: 'NEWE YAM'
    },
    {
        id: 686,
        value: 'נווה ימין',
        engName: 'NEWE YAMIN'
    },
    {
        id: 858,
        value: 'נווה ירק',
        engName: 'NEWE YARAQ'
    },
    {
        id: 827,
        value: 'נווה מבטח',
        engName: 'NEWE MIVTAH'
    },
    {
        id: 1071,
        value: 'נווה מיכאל',
        engName: "NEWE MIKHA'EL"
    },
    {
        id: 1259,
        value: 'נווה שלום',
        engName: 'NEWE SHALOM'
    },
    {
        id: 15,
        value: 'נועם',
        engName: "NO'AM"
    },
    {
        id: 1333,
        value: 'נוף איילון',
        engName: 'NOF AYYALON'
    },
    {
        id: 3790,
        value: 'נופים',
        engName: 'NOFIM'
    },
    {
        id: 1284,
        value: 'נופית',
        engName: 'NOFIT'
    },
    {
        id: 257,
        value: 'נופך',
        engName: 'NOFEKH'
    },
    {
        id: 3726,
        value: 'נוקדים',
        engName: 'NOQEDIM'
    },
    {
        id: 447,
        value: 'נורדייה',
        engName: 'NORDIYYA'
    },
    {
        id: 833,
        value: 'נורית',
        engName: 'NURIT'
    },
    {
        id: 59,
        value: 'נחושה',
        engName: 'NEHUSHA'
    },
    {
        id: 844,
        value: 'נחל עוז',
        engName: 'NAHAL OZ'
    },
    {
        id: 2045,
        value: 'נחלה',
        engName: 'NAHALA'
    },
    {
        id: 3767,
        value: 'נחליאל',
        engName: "NAHALI'EL"
    },
    {
        id: 449,
        value: 'נחלים',
        engName: 'NEHALIM'
    },
    {
        id: 809,
        value: 'נחם',
        engName: 'NAHAM'
    },
    {
        id: 522,
        value: 'נחף',
        engName: 'NAHEF'
    },
    {
        id: 433,
        value: 'נחשולים',
        engName: 'NAHSHOLIM'
    },
    {
        id: 777,
        value: 'נחשון',
        engName: 'NAHSHON'
    },
    {
        id: 705,
        value: 'נחשונים',
        engName: 'NAHSHONIM'
    },
    {
        id: 1147,
        value: 'נטועה',
        engName: "NETU'A"
    },
    {
        id: 4014,
        value: 'נטור',
        engName: 'NATUR'
    },
    {
        id: 1369,
        value: 'נטע',
        engName: 'NETA'
    },
    {
        id: 174,
        value: 'נטעים',
        engName: "NETA'IM"
    },
    {
        id: 1254,
        value: 'נטף',
        engName: 'NATAF'
    },
    {
        id: 523,
        value: 'ניין',
        engName: 'NEIN'
    },
    {
        id: 3655,
        value: 'ניל',
        engName: 'NILI'
    },
    {
        id: 351,
        value: 'ניצן',
        engName: 'NIZZAN'
    },
    {
        id: 1419,
        value: "ניצן ב'",
        engName: 'NIZZAN B'
    },
    {
        id: 1195,
        value: 'ניצנה (קהילת חינוך)',
        engName: 'NIZZANA (QEHILAT HINUH)'
    },
    {
        id: 1280,
        value: 'ניצני סיני',
        engName: 'NIZZANE SINAY'
    },
    {
        id: 851,
        value: 'ניצני עוז',
        engName: 'NIZZANE OZ'
    },
    {
        id: 359,
        value: 'ניצנים',
        engName: 'NIZZANIM'
    },
    {
        id: 808,
        value: 'ניר אליהו',
        engName: 'NIR ELIYYAHU'
    },
    {
        id: 553,
        value: 'ניר בנים',
        engName: 'NIR BANIM'
    },
    {
        id: 720,
        value: 'ניר גלים',
        engName: 'NIR GALLIM'
    },
    {
        id: 256,
        value: 'ניר דוד (תל עמל)',
        engName: 'NIR DAWID (TEL AMAL)'
    },
    {
        id: 11,
        value: 'ניר ח',
        engName: 'NIR HEN'
    },
    {
        id: 165,
        value: 'ניר יפה',
        engName: 'NIR YAFE'
    },
    {
        id: 402,
        value: 'ניר יצחק',
        engName: 'NIR YIZHAQ'
    },
    {
        id: 699,
        value: 'ניר ישראל',
        engName: "NIR YISRA'EL"
    },
    {
        id: 2047,
        value: 'ניר משה',
        engName: 'NIR MOSHE'
    },
    {
        id: 69,
        value: 'ניר עוז',
        engName: 'NIR OZ'
    },
    {
        id: 348,
        value: 'ניר עם',
        engName: 'NIR AM'
    },
    {
        id: 769,
        value: 'ניר עציון',
        engName: 'NIR EZYON'
    },
    {
        id: 2048,
        value: 'ניר עקיבא',
        engName: 'NIR AQIVA'
    },
    {
        id: 331,
        value: 'ניר צבי',
        engName: 'NIR ZEVI'
    },
    {
        id: 602,
        value: 'נירים',
        engName: 'NIRIM'
    },
    {
        id: 1236,
        value: 'נירית',
        engName: 'NIRIT'
    },
    {
        id: 3620,
        value: 'נירן',
        engName: 'NIRAN'
    },
    {
        id: 825,
        value: 'נס הרים',
        engName: 'NES HARIM'
    },
    {
        id: 1143,
        value: 'נס עמים',
        engName: 'NES AMMIM'
    },
    {
        id: 7200,
        value: 'נס ציונה',
        engName: 'NES ZIYYONA'
    },
    {
        id: 186,
        value: 'נעורים',
        engName: "NE'URIM"
    },
    {
        id: 3787,
        value: 'נעלה',
        engName: "NA'ALE"
    },
    {
        id: 3713,
        value: 'נעמ',
        engName: "NA'AMA"
    },
    {
        id: 158,
        value: 'נען',
        engName: "NA'AN"
    },
    {
        id: 1041,
        value: 'נצאצרה (שבט)',
        engName: 'NASASRA'
    },
    {
        id: 1372,
        value: 'נצר חזני',
        engName: 'NEZER HAZZANI'
    },
    {
        id: 435,
        value: 'נצר סרני',
        engName: 'NEZER SERENI'
    },
    {
        id: 7300,
        value: 'נצרת',
        engName: 'NAZARETH'
    },
    {
        id: 1061,
        value: 'נצרת עילית',
        engName: 'NAZERAT ILLIT'
    },
    {
        id: 2500,
        value: 'נשר',
        engName: 'NESHER'
    },
    {
        id: 3555,
        value: 'נתיב הגדוד',
        engName: 'NETIV HAGEDUD'
    },
    {
        id: 693,
        value: 'נתיב הל',
        engName: 'NETIV HALAMED-HE'
    },
    {
        id: 1242,
        value: 'נתיב העשרה',
        engName: 'NETIV HAASARA'
    },
    {
        id: 792,
        value: 'נתיב השיירה',
        engName: 'NETIV HASHAYYARA'
    },
    {
        id: 246,
        value: 'נתיבות',
        engName: 'NETIVOT'
    },
    {
        id: 7400,
        value: 'נתניה',
        engName: 'NETANYA'
    },
    {
        id: 525,
        value: "סאג'ור",
        engName: 'SAJUR'
    },
    {
        id: 578,
        value: 'סאסא',
        engName: 'SASA'
    },
    {
        id: 587,
        value: 'סביון*',
        engName: 'SAVYON'
    },
    {
        id: 2046,
        value: 'סגולה',
        engName: 'SEGULLA'
    },
    {
        id: 942,
        value: 'סואעד (חמרייה)*',
        engName: "SAWA'ID(HAMRIYYE)"
    },
    {
        id: 989,
        value: 'סואעד (כמאנה) (שבט)',
        engName: "SAWA'ID (KAMANE)"
    },
    {
        id: 526,
        value: 'סולם',
        engName: 'SULAM'
    },
    {
        id: 3756,
        value: 'סוסיה',
        engName: 'SUSEYA'
    },
    {
        id: 1238,
        value: 'סופה',
        engName: 'SUFA'
    },
    {
        id: 7500,
        value: "סח'נין",
        engName: 'SAKHNIN'
    },
    {
        id: 1170,
        value: 'סייד (שבט)',
        engName: 'SAYYID'
    },
    {
        id: 1245,
        value: 'סלמה',
        engName: 'SALLAMA'
    },
    {
        id: 3567,
        value: 'סלעית',
        engName: "SAL'IT"
    },
    {
        id: 1156,
        value: 'סמר',
        engName: 'SAMAR'
    },
    {
        id: 3777,
        value: 'סנסנה',
        engName: 'SANSANA'
    },
    {
        id: 419,
        value: 'סעד',
        engName: "SA'AD"
    },
    {
        id: 454,
        value: 'סער',
        engName: "SA'AR"
    },
    {
        id: 1176,
        value: 'ספיר',
        engName: 'SAPPIR'
    },
    {
        id: 610,
        value: 'סתרייה',
        engName: 'SITRIYYA'
    },
    {
        id: 4501,
        value: "ע'ג'ר",
        engName: 'GHAJAR'
    },
    {
        id: 892,
        value: 'עבדון',
        engName: 'AVDON'
    },
    {
        id: 376,
        value: 'עברון',
        engName: 'EVRON'
    },
    {
        id: 794,
        value: 'עגור',
        engName: 'AGUR'
    },
    {
        id: 1199,
        value: 'עדי',
        engName: 'ADI'
    },
    {
        id: 2035,
        value: 'עדנים',
        engName: 'ADANIM'
    },
    {
        id: 826,
        value: 'עוזה',
        engName: 'UZA'
    },
    {
        id: 528,
        value: 'עוזייר',
        engName: 'UZEIR'
    },
    {
        id: 737,
        value: 'עולש',
        engName: 'OLESH'
    },
    {
        id: 666,
        value: 'עומר',
        engName: 'OMER'
    },
    {
        id: 810,
        value: 'עופר',
        engName: 'OFER'
    },
    {
        id: 32,
        value: 'עוצם',
        engName: 'OZEM'
    },
    {
        id: 957,
        value: 'עוקבי (בנו עוקבה) (שבט)',
        engName: 'UQBI (BANU UQBA)'
    },
    {
        id: 328,
        value: 'עזוז',
        engName: 'EZUZ'
    },
    {
        id: 1149,
        value: 'עזר',
        engName: 'EZER'
    },
    {
        id: 837,
        value: 'עזריאל',
        engName: "AZRI'EL"
    },
    {
        id: 711,
        value: 'עזריה',
        engName: 'AZARYA'
    },
    {
        id: 817,
        value: 'עזריקם',
        engName: 'AZRIQAM'
    },
    {
        id: 969,
        value: 'עטאוונה (שבט)',
        engName: 'ATAWNE'
    },
    {
        id: 3658,
        value: 'עטרת',
        engName: 'ATERET'
    },
    {
        id: 1175,
        value: 'עידן',
        engName: 'IDDAN'
    },
    {
        id: 530,
        value: 'עיילבון',
        engName: 'EILABUN'
    },
    {
        id: 156,
        value: 'עיינות',
        engName: 'AYANOT'
    },
    {
        id: 511,
        value: 'עילוט',
        engName: 'ILUT'
    },
    {
        id: 687,
        value: 'עין איילה',
        engName: 'EN AYYALA'
    },
    {
        id: 546,
        value: 'עין אל-אסד',
        engName: 'EIN AL-ASAD'
    },
    {
        id: 273,
        value: 'עין גב',
        engName: 'EN GEV'
    },
    {
        id: 2042,
        value: 'עין גדי',
        engName: 'EN GEDI'
    },
    {
        id: 436,
        value: 'עין דור',
        engName: 'EN DOR'
    },
    {
        id: 1240,
        value: 'עין הבשור',
        engName: 'EN HABESOR'
    },
    {
        id: 74,
        value: 'עין הוד',
        engName: 'EN HOD'
    },
    {
        id: 167,
        value: 'עין החורש',
        engName: 'EN HAHORESH'
    },
    {
        id: 289,
        value: 'עין המפרץ',
        engName: 'EN HAMIFRAZ'
    },
    {
        id: 383,
        value: 'עין הנצי',
        engName: 'EN HANAZIV'
    },
    {
        id: 367,
        value: 'עין העמק',
        engName: 'EN HAEMEQ'
    },
    {
        id: 270,
        value: 'עין השופט',
        engName: 'EN HASHOFET'
    },
    {
        id: 676,
        value: 'עין השלושה',
        engName: 'EN HASHELOSHA'
    },
    {
        id: 157,
        value: 'עין ורד',
        engName: 'EN WERED'
    },
    {
        id: 4503,
        value: 'עין זיוון',
        engName: 'EN ZIWAN'
    },
    {
        id: 1320,
        value: 'עין חוד',
        engName: 'EIN HOD'
    },
    {
        id: 1053,
        value: 'עין חצבה',
        engName: 'EN HAZEVA'
    },
    {
        id: 89,
        value: 'עין חרוד (איחוד)',
        engName: 'EN HAROD (IHUD)'
    },
    {
        id: 82,
        value: 'עין חרוד (מאוחד)',
        engName: "EN HAROD(ME'UHAD)"
    },
    {
        id: 806,
        value: 'עין יהב',
        engName: 'EN YAHAV'
    },
    {
        id: 813,
        value: 'עין יעקב',
        engName: "EN YA'AQOV"
    },
    {
        id: 1056,
        value: 'עין כרם-בי',
        engName: "EN KAREM-B.S.HAQLA'I"
    },
    {
        id: 426,
        value: 'עין כרמל',
        engName: 'EN KARMEL'
    },
    {
        id: 532,
        value: 'עין מאהל',
        engName: 'EIN MAHEL'
    },
    {
        id: 521,
        value: 'עין נקובא',
        engName: 'EIN NAQQUBA'
    },
    {
        id: 223,
        value: 'עין עירון',
        engName: 'EN IRON'
    },
    {
        id: 622,
        value: 'עין צורים',
        engName: 'EN ZURIM'
    },
    {
        id: 4502,
        value: 'עין קנייא',
        engName: 'EIN QINIYYE'
    },
    {
        id: 514,
        value: 'עין ראפה',
        engName: 'EIN RAFA'
    },
    {
        id: 139,
        value: 'עין שמר',
        engName: 'EN SHEMER'
    },
    {
        id: 880,
        value: 'עין שריד',
        engName: 'EN SARID'
    },
    {
        id: 1251,
        value: 'עין תמר',
        engName: 'EN TAMAR'
    },
    {
        id: 871,
        value: 'עינת',
        engName: 'ENAT'
    },
    {
        id: 1187,
        value: 'עיר אובות',
        engName: 'IR OVOT'
    },
    {
        id: 7600,
        value: 'עכו',
        engName: 'AKKO'
    },
    {
        id: 1146,
        value: 'עלומים',
        engName: 'ALUMIM'
    },
    {
        id: 3765,
        value: 'עלי',
        engName: 'ELI'
    },
    {
        id: 3727,
        value: 'עלי זהב',
        engName: 'ALE ZAHAV'
    },
    {
        id: 688,
        value: 'עלמה',
        engName: 'ALMA'
    },
    {
        id: 3715,
        value: 'עלמון',
        engName: 'ALMON'
    },
    {
        id: 1212,
        value: 'עמוקה',
        engName: 'AMUQQA'
    },
    {
        id: 779,
        value: 'עמינדב',
        engName: 'AMMINADAV'
    },
    {
        id: 385,
        value: 'עמיעד',
        engName: "AMMI'AD"
    },
    {
        id: 318,
        value: 'עמיעוז',
        engName: "AMMI'OZ"
    },
    {
        id: 773,
        value: 'עמיקם',
        engName: 'AMMIQAM'
    },
    {
        id: 319,
        value: 'עמיר',
        engName: 'AMIR'
    },
    {
        id: 3660,
        value: 'עמנואל',
        engName: "IMMANU'EL"
    },
    {
        id: 708,
        value: 'עמקה',
        engName: 'AMQA'
    },
    {
        id: 3712,
        value: 'ענב',
        engName: 'ENAV'
    },
    {
        id: 534,
        value: 'עספיא',
        engName: 'ISIFYA'
    },
    {
        id: 7700,
        value: 'עפולה',
        engName: 'AFULA'
    },
    {
        id: 3617,
        value: 'עפרה',
        engName: 'OFRA'
    },
    {
        id: 3778,
        value: 'עץ אפרים',
        engName: 'EZ EFRAYIM'
    },
    {
        id: 917,
        value: 'עצמון שגב',
        engName: 'ATSMON SEGEV'
    },
    {
        id: 531,
        value: 'עראבה',
        engName: 'ARRABE'
    },
    {
        id: 1246,
        value: 'עראמשה*',
        engName: 'ARAMSHA'
    },
    {
        id: 1335,
        value: 'ערב אל נעים',
        engName: 'ARAB AL NAIM'
    },
    {
        id: 2560,
        value: 'ערד',
        engName: 'ARAD'
    },
    {
        id: 593,
        value: 'ערוגות',
        engName: 'ARUGOT'
    },
    {
        id: 637,
        value: 'ערערה',
        engName: "AR'ARA"
    },
    {
        id: 1192,
        value: 'ערערה-בנגב',
        engName: "AR'ARA-BANEGEV"
    },
    {
        id: 591,
        value: 'עשרת',
        engName: 'ASERET'
    },
    {
        id: 53,
        value: 'עתלית',
        engName: 'ATLIT'
    },
    {
        id: 3748,
        value: 'עתניאל',
        engName: "OTNI'EL"
    },
    {
        id: 1151,
        value: 'פארן',
        engName: 'PARAN'
    },
    {
        id: 3768,
        value: 'פדואל',
        engName: "PEDU'EL"
    },
    {
        id: 750,
        value: 'פדויים',
        engName: 'PEDUYIM'
    },
    {
        id: 838,
        value: 'פדיה',
        engName: 'PEDAYA'
    },
    {
        id: 537,
        value: 'פוריידיס',
        engName: 'FUREIDIS'
    },
    {
        id: 1104,
        value: 'פורייה - כפר עבודה',
        engName: 'PORIYYA-KEFAR AVODA'
    },
    {
        id: 1105,
        value: 'פורייה - נווה עובד',
        engName: 'PORIYYA-NEWE OVED'
    },
    {
        id: 1313,
        value: 'פורייה עילית',
        engName: 'PORIYYA ILLIT'
    },
    {
        id: 767,
        value: 'פורת',
        engName: 'PORAT'
    },
    {
        id: 749,
        value: 'פטיש',
        engName: 'PATTISH'
    },
    {
        id: 1185,
        value: 'פלך',
        engName: 'PELEKH'
    },
    {
        id: 597,
        value: 'פלמחים',
        engName: 'PALMAHIM'
    },
    {
        id: 3723,
        value: 'פני חבר',
        engName: 'PENE HEVER'
    },
    {
        id: 3659,
        value: 'פסגות',
        engName: 'PESAGOT'
    },
    {
        id: 535,
        value: 'פסוטה',
        engName: 'FASSUTA'
    },
    {
        id: 2059,
        value: 'פעמי תש',
        engName: "PA'AME TASHAZ"
    },
    {
        id: 3615,
        value: 'פצאל',
        engName: "PEZA'EL"
    },
    {
        id: 536,
        value: 'פקיעין (בוקייעה)',
        engName: "PEQI'IN (BUQEI'A)"
    },
    {
        id: 281,
        value: 'פקיעין חדשה',
        engName: "PEQI'IN HADASHA"
    },
    {
        id: 7800,
        value: 'פרדס חנה-כרכור',
        engName: 'PARDES HANNA-KARKUR'
    },
    {
        id: 171,
        value: 'פרדסייה',
        engName: 'PARDESIYYA'
    },
    {
        id: 599,
        value: 'פרוד',
        engName: 'PAROD'
    },
    {
        id: 2053,
        value: 'פרזון',
        engName: 'PERAZON'
    },
    {
        id: 1231,
        value: 'פרי גן',
        engName: 'PERI GAN'
    },
    {
        id: 7900,
        value: 'פתח תקווה',
        engName: 'PETAH TIKVA'
    },
    {
        id: 839,
        value: 'פתחיה',
        engName: 'PETAHYA'
    },
    {
        id: 413,
        value: 'צאלים',
        engName: "ZE'ELIM"
    },
    {
        id: 1180,
        value: 'צביה',
        engName: 'ZVIYYA'
    },
    {
        id: 1213,
        value: 'צבעון',
        engName: "ZIV'ON"
    },
    {
        id: 465,
        value: 'צובה',
        engName: 'ZOVA'
    },
    {
        id: 1136,
        value: 'צוחר',
        engName: 'ZOHAR'
    },
    {
        id: 1111,
        value: 'צופייה',
        engName: 'ZOFIYYA'
    },
    {
        id: 3791,
        value: 'צופים',
        engName: 'ZUFIM'
    },
    {
        id: 198,
        value: 'צופית',
        engName: 'ZOFIT'
    },
    {
        id: 1150,
        value: 'צופר',
        engName: 'ZOFAR'
    },
    {
        id: 1102,
        value: 'צוקי ים',
        engName: 'ZUQI YAM'
    },
    {
        id: 1262,
        value: 'צוקים',
        engName: 'ZUQIM'
    },
    {
        id: 1113,
        value: 'צור הדסה',
        engName: 'ZUR HADASSA'
    },
    {
        id: 1345,
        value: 'צור יצחק',
        engName: 'ZUR YIZHAQ'
    },
    {
        id: 276,
        value: 'צור משה',
        engName: 'ZUR MOSHE'
    },
    {
        id: 1148,
        value: 'צור נתן',
        engName: 'ZUR NATAN'
    },
    {
        id: 774,
        value: 'צוריאל',
        engName: "ZURI'EL"
    },
    {
        id: 1221,
        value: 'צורית',
        engName: 'ZURIT'
    },
    {
        id: 613,
        value: 'ציפורי',
        engName: 'ZIPPORI'
    },
    {
        id: 796,
        value: 'צלפון',
        engName: 'ZELAFON'
    },
    {
        id: 636,
        value: 'צנדלה',
        engName: 'SANDALA'
    },
    {
        id: 594,
        value: 'צפרייה',
        engName: 'ZAFRIYYA'
    },
    {
        id: 1079,
        value: 'צפרירים',
        engName: 'ZAFRIRIM'
    },
    {
        id: 8000,
        value: 'צפת',
        engName: 'ZEFAT'
    },
    {
        id: 612,
        value: 'צרופה',
        engName: 'ZERUFA'
    },
    {
        id: 567,
        value: 'צרעה',
        engName: "ZOR'A"
    },
    {
        id: 1234,
        value: 'קבועה (שבט)',
        engName: "QABBO'A"
    },
    {
        id: 334,
        value: 'קבוצת יבנה',
        engName: 'QEVUZAT YAVNE'
    },
    {
        id: 3557,
        value: 'קדומים',
        engName: 'QEDUMIM'
    },
    {
        id: 195,
        value: 'קדימה-צורן',
        engName: 'QADIMA-ZORAN'
    },
    {
        id: 392,
        value: 'קדמה',
        engName: 'QEDMA'
    },
    {
        id: 4025,
        value: 'קדמת צבי',
        engName: 'QIDMAT ZEVI'
    },
    {
        id: 3781,
        value: 'קדר',
        engName: 'QEDAR'
    },
    {
        id: 615,
        value: 'קדרון',
        engName: 'QIDRON'
    },
    {
        id: 1211,
        value: 'קדרים',
        engName: 'QADDARIM'
    },
    {
        id: 964,
        value: 'קודייראת א-צאנע (שבט)',
        engName: 'QUDEIRAT AS-SANI'
    },
    {
        id: 972,
        value: 'קוואעין (שבט)',
        engName: "QAWA'IN"
    },
    {
        id: 766,
        value: 'קוממיות',
        engName: 'QOMEMIYYUT'
    },
    {
        id: 1179,
        value: 'קורנית',
        engName: 'QORANIT'
    },
    {
        id: 1052,
        value: 'קטורה',
        engName: 'QETURA'
    },
    {
        id: 1167,
        value: 'קיסריה',
        engName: 'QESARYYA'
    },
    {
        id: 414,
        value: 'קלחים',
        engName: 'QELAHIM'
    },
    {
        id: 3601,
        value: 'קליה',
        engName: 'QALYA'
    },
    {
        id: 638,
        value: 'קלנסווה',
        engName: 'QALANSAWE'
    },
    {
        id: 4024,
        value: 'קלע',
        engName: 'QELA'
    },
    {
        id: 1243,
        value: 'קציר                ',
        engName: 'QAZIR               '
    },
    {
        id: 1347,
        value: 'קצר א-סר',
        engName: 'QASR A-SIR'
    },
    {
        id: 4100,
        value: 'קצרין',
        engName: 'QAZRIN'
    },
    {
        id: 2620,
        value: 'קריית אונו',
        engName: 'QIRYAT ONO'
    },
    {
        id: 3611,
        value: 'קריית ארבע',
        engName: 'QIRYAT ARBA'
    },
    {
        id: 6800,
        value: 'קריית אתא',
        engName: 'QIRYAT ATTA'
    },
    {
        id: 9500,
        value: 'קריית ביאליק',
        engName: 'QIRYAT BIALIK'
    },
    {
        id: 2630,
        value: 'קריית גת',
        engName: 'QIRYAT GAT'
    },
    {
        id: 2300,
        value: 'קריית טבעון',
        engName: "QIRYAT TIV'ON"
    },
    {
        id: 9600,
        value: 'קריית ים',
        engName: 'QIRYAT YAM'
    },
    {
        id: 1137,
        value: 'קריית יערים',
        engName: "QIRYAT YE'ARIM"
    },
    {
        id: 2039,
        value: 'קריית יערים (מוסד)',
        engName: "QIRYAT YE'ARIM(INSTITUTE)"
    },
    {
        id: 8200,
        value: 'קריית מוצקין',
        engName: 'QIRYAT MOTZKIN'
    },
    {
        id: 1034,
        value: 'קריית מלאכי',
        engName: "QIRYAT MAL'AKHI"
    },
    {
        id: 3746,
        value: 'קריית נטפים',
        engName: 'QIRYAT NETAFIM'
    },
    {
        id: 78,
        value: 'קריית ענבים',
        engName: 'QIRYAT ANAVIM'
    },
    {
        id: 469,
        value: 'קריית עקרון',
        engName: 'QIRYAT EQRON'
    },
    {
        id: 412,
        value: 'קריית שלמה',
        engName: 'QIRYAT SHELOMO'
    },
    {
        id: 2800,
        value: 'קריית שמונה',
        engName: 'QIRYAT SHEMONA'
    },
    {
        id: 3640,
        value: 'קרני שומרון',
        engName: 'QARNE SHOMERON'
    },
    {
        id: 4006,
        value: 'קשת',
        engName: 'QESHET'
    },
    {
        id: 543,
        value: 'ראמה',
        engName: 'RAME'
    },
    {
        id: 1334,
        value: 'ראס אל-עין',
        engName: 'RAS AL-EIN'
    },
    {
        id: 990,
        value: 'ראס עלי',
        engName: 'RAS ALI'
    },
    {
        id: 2640,
        value: 'ראש העין',
        engName: 'ROSH HAAYIN'
    },
    {
        id: 26,
        value: 'ראש פינה',
        engName: 'ROSH PINNA'
    },
    {
        id: 3602,
        value: 'ראש צורים',
        engName: 'ROSH ZURIM'
    },
    {
        id: 8300,
        value: 'ראשון לציון',
        engName: 'RISHON LEZION'
    },
    {
        id: 3795,
        value: 'רבבה',
        engName: 'REVAVA'
    },
    {
        id: 564,
        value: 'רבדים',
        engName: 'REVADIM'
    },
    {
        id: 354,
        value: 'רביבים',
        engName: 'REVIVIM'
    },
    {
        id: 1225,
        value: 'רביד',
        engName: 'RAVID'
    },
    {
        id: 390,
        value: 'רגבה',
        engName: 'REGBA'
    },
    {
        id: 444,
        value: 'רגבים',
        engName: 'REGAVIM'
    },
    {
        id: 1161,
        value: 'רהט',
        engName: 'RAHAT'
    },
    {
        id: 2051,
        value: 'רווחה',
        engName: 'REWAHA'
    },
    {
        id: 2016,
        value: 'רוויה',
        engName: 'REWAYA'
    },
    {
        id: 1341,
        value: 'רוח מדבר',
        engName: 'RUAH MIDBAR'
    },
    {
        id: 362,
        value: 'רוחמה',
        engName: 'RUHAMA'
    },
    {
        id: 539,
        value: 'רומאנה',
        engName: 'RUMMANE'
    },
    {
        id: 997,
        value: 'רומת הייב',
        engName: 'RUMAT HEIB'
    },
    {
        id: 3619,
        value: 'רועי',
        engName: "RO'I"
    },
    {
        id: 3782,
        value: 'רותם',
        engName: 'ROTEM'
    },
    {
        id: 854,
        value: 'רחוב',
        engName: 'REHOV'
    },
    {
        id: 8400,
        value: 'רחובות',
        engName: 'REHOVOT'
    },
    {
        id: 3822,
        value: 'רחלים',
        engName: 'REHELIM'
    },
    {
        id: 540,
        value: 'ריחאנייה',
        engName: 'REIHANIYYE'
    },
    {
        id: 3568,
        value: 'ריחן',
        engName: 'REHAN'
    },
    {
        id: 542,
        value: 'ריינה',
        engName: 'REINE'
    },
    {
        id: 3565,
        value: 'רימונים',
        engName: 'RIMMONIM'
    },
    {
        id: 616,
        value: 'רינתיה',
        engName: 'RINNATYA'
    },
    {
        id: 922,
        value: 'רכסים',
        engName: 'REKHASIM'
    },
    {
        id: 1069,
        value: 'רם-און',
        engName: 'RAM-ON'
    },
    {
        id: 4702,
        value: 'רמות',
        engName: 'RAMOT'
    },
    {
        id: 206,
        value: 'רמות השבים',
        engName: 'RAMOT HASHAVIM'
    },
    {
        id: 735,
        value: 'רמות מאיר',
        engName: "RAMOT ME'IR"
    },
    {
        id: 445,
        value: 'רמות מנשה',
        engName: 'RAMOT MENASHE'
    },
    {
        id: 372,
        value: 'רמות נפתלי',
        engName: 'RAMOT NAFTALI'
    },
    {
        id: 8500,
        value: 'רמלה',
        engName: 'RAMLA'
    },
    {
        id: 8600,
        value: 'רמת גן',
        engName: 'RAMAT GAN'
    },
    {
        id: 135,
        value: 'רמת דוד',
        engName: 'RAMAT DAWID'
    },
    {
        id: 184,
        value: 'רמת הכובש',
        engName: 'RAMAT HAKOVESH'
    },
    {
        id: 335,
        value: 'רמת השופט',
        engName: 'RAMAT HASHOFET'
    },
    {
        id: 2650,
        value: 'רמת השרון',
        engName: 'RAMAT HASHARON'
    },
    {
        id: 178,
        value: 'רמת יוחנן',
        engName: 'RAMAT YOHANAN'
    },
    {
        id: 122,
        value: 'רמת ישי',
        engName: 'RAMAT YISHAY'
    },
    {
        id: 4701,
        value: 'רמת מגשימים',
        engName: 'RAMAT MAGSHIMIM'
    },
    {
        id: 339,
        value: 'רמת צבי',
        engName: 'RAMAT ZEVI'
    },
    {
        id: 460,
        value: 'רמת רזיאל',
        engName: "RAMAT RAZI'EL"
    },
    {
        id: 127,
        value: 'רמת רחל',
        engName: 'RAMAT RAHEL'
    },
    {
        id: 789,
        value: 'רנן',
        engName: 'RANNEN'
    },
    {
        id: 713,
        value: 'רעים',
        engName: "RE'IM"
    },
    {
        id: 8700,
        value: 'רעננה',
        engName: "RA'ANNANA"
    },
    {
        id: 1228,
        value: 'רקפת',
        engName: 'RAQQEFET'
    },
    {
        id: 247,
        value: 'רשפון',
        engName: 'RISHPON'
    },
    {
        id: 437,
        value: 'רשפים',
        engName: 'RESHAFIM'
    },
    {
        id: 1260,
        value: 'רתמים',
        engName: 'RETAMIM'
    },
    {
        id: 324,
        value: 'שאר ישוב',
        engName: "SHE'AR YASHUV"
    },
    {
        id: 282,
        value: 'שבי ציון',
        engName: 'SHAVE ZIYYON'
    },
    {
        id: 3571,
        value: 'שבי שומרון',
        engName: 'SHAVE SHOMERON'
    },
    {
        id: 913,
        value: 'שבלי - אום אל-גנם',
        engName: 'SHIBLI-UMM AL-GHANAM'
    },
    {
        id: 1286,
        value: 'שגב-שלום',
        engName: 'SEGEV-SHALOM'
    },
    {
        id: 721,
        value: 'שדה אילן',
        engName: 'SEDE ILAN'
    },
    {
        id: 304,
        value: 'שדה אליהו',
        engName: 'SEDE ELIYYAHU'
    },
    {
        id: 861,
        value: 'שדה אליעזר',
        engName: "SEDE ELI'EZER"
    },
    {
        id: 885,
        value: 'שדה בוקר',
        engName: 'SEDE BOQER'
    },
    {
        id: 36,
        value: 'שדה דוד',
        engName: 'SEDE DAWID'
    },
    {
        id: 284,
        value: 'שדה ורבורג',
        engName: 'SEDE WARBURG'
    },
    {
        id: 293,
        value: 'שדה יואב',
        engName: "SEDE YO'AV"
    },
    {
        id: 142,
        value: 'שדה יעקב',
        engName: "SEDE YA'AQOV"
    },
    {
        id: 2008,
        value: 'שדה יצחק',
        engName: 'SEDE YIZHAQ'
    },
    {
        id: 18,
        value: 'שדה משה',
        engName: 'SEDE MOSHE'
    },
    {
        id: 259,
        value: 'שדה נחום',
        engName: 'SEDE NAHUM'
    },
    {
        id: 329,
        value: 'שדה נחמיה',
        engName: 'SEDE NEHEMYA'
    },
    {
        id: 1058,
        value: 'שדה ניצן',
        engName: 'SEDE NIZZAN'
    },
    {
        id: 739,
        value: 'שדה עוזיהו',
        engName: 'SEDE UZZIYYAHU'
    },
    {
        id: 2049,
        value: 'שדה צבי',
        engName: 'SEDE ZEVI'
    },
    {
        id: 327,
        value: 'שדות ים',
        engName: 'SEDOT YAM'
    },
    {
        id: 27,
        value: 'שדות מיכה',
        engName: 'SEDOT MIKHA'
    },
    {
        id: 1223,
        value: 'שדי אברהם',
        engName: 'SEDE AVRAHAM'
    },
    {
        id: 2015,
        value: 'שדי חמד',
        engName: 'SEDE HEMED'
    },
    {
        id: 2057,
        value: 'שדי תרומות',
        engName: 'SEDE TERUMOT'
    },
    {
        id: 555,
        value: 'שדמה',
        engName: 'SHEDEMA'
    },
    {
        id: 306,
        value: 'שדמות דבורה',
        engName: 'SHADMOT DEVORA'
    },
    {
        id: 3578,
        value: 'שדמות מחולה',
        engName: 'SHADMOT MEHOLA'
    },
    {
        id: 1031,
        value: 'שדרות',
        engName: 'SEDEROT'
    },
    {
        id: 741,
        value: 'שואבה',
        engName: "SHO'EVA"
    },
    {
        id: 761,
        value: 'שובה',
        engName: 'SHUVA'
    },
    {
        id: 394,
        value: 'שובל',
        engName: 'SHOVAL'
    },
    {
        id: 1304,
        value: 'שוהם',
        engName: 'SHOHAM'
    },
    {
        id: 614,
        value: 'שומרה',
        engName: 'SHOMERA'
    },
    {
        id: 1265,
        value: 'שומרייה',
        engName: 'SHOMERIYYA'
    },
    {
        id: 415,
        value: 'שוקדה',
        engName: 'SHOQEDA'
    },
    {
        id: 456,
        value: 'שורש',
        engName: 'SHORESH'
    },
    {
        id: 1235,
        value: 'שורשים',
        engName: 'SHORASHIM'
    },
    {
        id: 224,
        value: 'שושנת העמקים',
        engName: 'SHOSHANNAT HAAMAQIM'
    },
    {
        id: 527,
        value: 'שזור',
        engName: 'SHEZOR'
    },
    {
        id: 7,
        value: 'שחר',
        engName: 'SHAHAR'
    },
    {
        id: 1266,
        value: 'שחרות',
        engName: 'SHAHARUT'
    },
    {
        id: 865,
        value: 'שיבולים',
        engName: 'SHIBBOLIM'
    },
    {
        id: 1267,
        value: 'שיטים',
        engName: 'SHITTIM'
    },
    {
        id: 658,
        value: "שייח' דנון",
        engName: 'SHEIKH DANNUN'
    },
    {
        id: 3641,
        value: 'שילה',
        engName: 'SHILO'
    },
    {
        id: 1165,
        value: 'שילת',
        engName: 'SHILAT'
    },
    {
        id: 1160,
        value: 'שכניה',
        engName: 'SHEKHANYA'
    },
    {
        id: 873,
        value: 'שלווה',
        engName: 'SHALWA'
    },
    {
        id: 1373,
        value: 'שלווה במדבר',
        engName: 'SHALVA BAMIDBAR'
    },
    {
        id: 439,
        value: 'שלוחות',
        engName: 'SHELUHOT'
    },
    {
        id: 812,
        value: 'שלומי',
        engName: 'SHELOMI'
    },
    {
        id: 1364,
        value: 'שלומית',
        engName: 'SHLOMIT'
    },
    {
        id: 366,
        value: 'שמיר',
        engName: 'SHAMIR'
    },
    {
        id: 3784,
        value: 'שמעה',
        engName: "SHIM'A"
    },
    {
        id: 432,
        value: 'שמרת',
        engName: 'SHAMERAT'
    },
    {
        id: 1337,
        value: 'שמשית',
        engName: 'SHIMSHIT'
    },
    {
        id: 1287,
        value: 'שני',
        engName: 'SHANI'
    },
    {
        id: 1132,
        value: 'שניר',
        engName: 'SENIR'
    },
    {
        id: 538,
        value: 'שעב',
        engName: "SHA'AB"
    },
    {
        id: 4009,
        value: 'שעל',
        engName: "SHA'AL"
    },
    {
        id: 856,
        value: 'שעלבים',
        engName: "SHA'ALVIM"
    },
    {
        id: 661,
        value: 'שער אפרים',
        engName: "SHA'AR EFRAYIM"
    },
    {
        id: 264,
        value: 'שער הגולן',
        engName: "SHA'AR HAGOLAN"
    },
    {
        id: 237,
        value: 'שער העמקים',
        engName: "SHA'AR HAAMAQIM"
    },
    {
        id: 921,
        value: 'שער מנשה',
        engName: "SHA'AR MENASHE"
    },
    {
        id: 3720,
        value: 'שערי תקווה',
        engName: "SHA'ARE TIQWA"
    },
    {
        id: 232,
        value: 'שפיים',
        engName: 'SHEFAYIM'
    },
    {
        id: 692,
        value: 'שפיר',
        engName: 'SHAFIR'
    },
    {
        id: 846,
        value: 'שפר',
        engName: 'SHEFER'
    },
    {
        id: 8800,
        value: 'שפרעם',
        engName: "SHEFAR'AM"
    },
    {
        id: 3649,
        value: 'שקד',
        engName: 'SHAQED'
    },
    {
        id: 1233,
        value: 'שקף',
        engName: 'SHEQEF'
    },
    {
        id: 292,
        value: 'שרונה',
        engName: 'SHARONA'
    },
    {
        id: 1114,
        value: 'שריגים (לי-און)',
        engName: 'SARIGIM (LI-ON)'
    },
    {
        id: 126,
        value: 'שריד',
        engName: 'SARID'
    },
    {
        id: 398,
        value: 'שרשרת',
        engName: 'SHARSHERET'
    },
    {
        id: 1045,
        value: 'שתולה',
        engName: 'SHETULA'
    },
    {
        id: 763,
        value: 'שתולים',
        engName: 'SHETULIM'
    },
    {
        id: 2062,
        value: 'תאשור',
        engName: "TE'ASHUR"
    },
    {
        id: 2061,
        value: 'תדהר',
        engName: 'TIDHAR'
    },
    {
        id: 1172,
        value: 'תובל',
        engName: 'TUVAL'
    },
    {
        id: 3558,
        value: 'תומר',
        engName: 'TOMER'
    },
    {
        id: 1083,
        value: 'תושייה',
        engName: 'TUSHIYYA'
    },
    {
        id: 163,
        value: 'תימורים',
        engName: 'TIMMORIM'
    },
    {
        id: 10,
        value: 'תירוש',
        engName: 'TIROSH'
    },
    {
        id: 5000,
        value: 'תל אביב -יפו',
        engName: 'TEL AVIV - YAFO'
    },
    {
        id: 84,
        value: 'תל יוסף',
        engName: 'TEL YOSEF'
    },
    {
        id: 287,
        value: 'תל יצחק',
        engName: 'TEL YIZHAQ'
    },
    {
        id: 154,
        value: 'תל מונד',
        engName: 'TEL MOND'
    },
    {
        id: 103,
        value: 'תל עדשים',
        engName: 'TEL ADASHIM'
    },
    {
        id: 719,
        value: 'תל קציר',
        engName: 'TEL QAZIR'
    },
    {
        id: 1054,
        value: 'תל שבע',
        engName: 'TEL SHEVA'
    },
    {
        id: 1283,
        value: 'תל תאומים',
        engName: "TEL TE'OMIM"
    },
    {
        id: 3719,
        value: 'תלם',
        engName: 'TELEM'
    },
    {
        id: 1051,
        value: 'תלמי אליהו',
        engName: 'TALME ELIYYAHU'
    },
    {
        id: 2003,
        value: 'תלמי אלעזר',
        engName: "TALME EL'AZAR"
    },
    {
        id: 2050,
        value: 'תלמי ביל',
        engName: 'TALME BILU'
    },
    {
        id: 1237,
        value: 'תלמי יוסף',
        engName: 'TALME YOSEF'
    },
    {
        id: 727,
        value: 'תלמי יחיאל',
        engName: "TALME YEHI'EL"
    },
    {
        id: 744,
        value: 'תלמי יפה',
        engName: 'TALME YAFE'
    },
    {
        id: 814,
        value: 'תלמים',
        engName: 'TELAMIM'
    },
    {
        id: 1244,
        value: 'תמרת',
        engName: 'TIMRAT'
    },
    {
        id: 2002,
        value: 'תנובות',
        engName: 'TENUVOT'
    },
    {
        id: 752,
        value: 'תעוז',
        engName: "TA'OZ"
    },
    {
        id: 709,
        value: 'תפרח',
        engName: 'TIFRAH'
    },
    {
        id: 665,
        value: 'תקומה',
        engName: 'TEQUMA'
    },
    {
        id: 3563,
        value: 'תקוע',
        engName: 'TEQOA'
    },
    {
        id: 970,
        value: 'תראבין א-צאנע (שבט)',
        engName: 'TARABIN AS-SANI'
    },
    {
        id: 1346,
        value: 'תרבין א-צאנע (יישוב)*',
        engName: 'TARABIN AS-SANI'
    },
    {
        id: 778,
        value: 'תרום',
        engName: 'TARUM'
    }
];
